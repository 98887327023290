.cards-d {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.mode.modal-parent-wrap .raven-modal-content-wrap {
  width: 34% !important;
}

.icon-arrow {
  display: flex;
  margin-left: 1rem;
}

.group {
  display: flex;
  align-items: center;
}

.img- {
  display: flex;
  margin-left: 1rem;
  background-color: rgb(255, 246, 237);
  padding: 1rem;
  border-radius: 100%;
}

.my_custom {
  margin-top: 2rem !important;
  font-size: 1.6rem !important;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
}

.card-details-button {
  background-color: rgb(247, 248, 247);
  border: none;
  border-radius: 16px;
  padding: 6px 12px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.tabs-navigation {
  background-color: rgb(249, 251, 252);
  display: flex;
  border-radius: 1rem;
  justify-content: center;
  gap: 4rem;
  padding: 1.2rem;
}

.tab-btn {
  border-radius: 1rem;
  background-color: transparent;
  border: 1px solid #ccc;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  cursor: pointer;
  color: rgb(100, 100, 100);
  border: none;
}

.tab-btn.active {
  background-color: rgb(255, 255, 255);
  color: rgb(1, 67, 69);
  border: none;
  font-weight: 600;
}

.tab-content {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}/*# sourceMappingURL=singlesettlement.css.map */