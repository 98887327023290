.small-mobile-flag-out-wrap-index-box {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: grid;
  place-items: center;

  @media only screen and (max-width: 48em) {
    padding: 3rem;
  }

  .main-wrapper {
    width: 80%;
    height: 80%;
    // background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 48em) {
      width: 100%;
      height: max-content;
    }

    .logo-box {
      .img-box {
        width: 15rem;
        height: 4rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .mobile-box {
      width: 35rem;
      height: 35rem;

      @media only screen and (max-width: 48em) {
        width: 100%;
        height: 30rem;
      }

      .img-box {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .big-text-box {
      .text {
        font-size: 3rem;
        font-weight: 600;
        color: #000000;
        text-align: center;

        @media only screen and (max-width: 48em) {
          font-size: 2rem;
        }
      }
    }
  }
}
