.reusable-description-box-wrapper-index {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.reusable-description-box-wrapper-index .top-box {
  height: 30rem;
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(233, 245, 255));
  position: relative;
}
.reusable-description-box-wrapper-index .top-box::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60%;
  width: 50%;
  transform: translate(-50%, -30%);
  background-image: url("../../../../../../../assets/center-expire-img.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.reusable-description-box-wrapper-index .top-box-wrong {
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(255, 239, 238));
}
.reusable-description-box-wrapper-index .top-box-wrong::after {
  background-image: url("../../../../../../../assets/wrong-centered-img.png");
}
.reusable-description-box-wrapper-index .top-box-success {
  background: linear-gradient(to bottom, rgb(242, 252, 244), rgb(230, 249, 234));
}
.reusable-description-box-wrapper-index .top-box-success::after {
  background-image: url("../../../../../../../assets/success-invite-img-centered.png");
}
.reusable-description-box-wrapper-index .bottom-box {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.reusable-description-box-wrapper-index .bottom-box .title {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 1rem;
}
.reusable-description-box-wrapper-index .bottom-box .text {
  font-size: 1.55rem;
  color: rgb(139, 139, 139);
  line-height: 2rem;
  text-align: center;
}/*# sourceMappingURL=ReuseableDescription.css.map */