.smartfilter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.smartfilter .top-bar__filter-export {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  z-index: 1;
  position: relative;
}
.smartfilter .top-bar__filter-export .filter-export__export-btn,
.smartfilter .top-bar__filter-export .filter-export__filter-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1.6rem 2.4rem;
  gap: 0.8em;
  cursor: pointer;
  height: 5rem;
  background: #f7f8f7;
  border-radius: 1rem;
  z-index: 100;
}
.smartfilter .top-bar__filter-export .filter-export__export-btn p,
.smartfilter .top-bar__filter-export .filter-export__filter-btn p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.smartfilter .top-bar__filter-export .filter-export__export-btn figure,
.smartfilter .top-bar__filter-export .filter-export__filter-btn figure {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
}
.smartfilter .top-bar__filter-export .filter-export__export-btn figure svg,
.smartfilter .top-bar__filter-export .filter-export__filter-btn figure svg {
  width: 2rem;
  height: 2rem;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down-wrap {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 21rem;
  max-width: 45rem;
  padding-bottom: 9rem;
  max-height: 400px;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down-wrap::-webkit-scrollbar {
  display: none;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down {
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  top: 110%;
  right: 55%;
  transform-origin: top;
  transform: translateY(-10%);
  opacity: 0.8;
  z-index: 100;
  border-radius: 8px;
  border: 0.5px solid #e5e6e6;
  background: #fff;
  /* Raven/Shadow XS */
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  padding-bottom: 9 rem;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down--open {
  transform: translateY(0%);
  opacity: 1;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  justify-content: space-between;
  padding: 1.8rem;
  border-bottom: 1px solid #eee;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__header p {
  font-size: 1.4rem;
  color: #000;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__header p:last-child {
  color: var(--error-100, #ff0f00);
  font-weight: 500;
  cursor: pointer;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__date-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  justify-content: space-between;
  padding: 1.8rem;
  width: 100%;
  cursor: pointer;
  flex: 1;
  border-bottom: 1px solid #eee;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;
  background: var(--base-background, #f9f9f9);
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
  position: relative;
  animation-timing-function: ease-in-out;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item.active {
  max-height: 500rem;
  transition: max-height 3s;
  animation-timing-function: ease, step-start, cubic-bezier(0.1, 0.7, 1, 0.1);
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item span, .smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .span {
  display: flex;
  padding: 1.2rem 2.4rem;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 0.4px solid var(--ravengreyshade-50, #e5e6e6);
  gap: 1.2rem;
  cursor: pointer;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item span p, .smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .span p {
  color: var(--raven-black-600, #646464);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date {
  flex-direction: column;
  width: 100%;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date__select {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date__form-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 1.6rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0.445, 0.05, 0.25, 0.94);
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date__form-wrap .form-group__deep-green-light .form-input {
  background: var(--raven-black-100, #eee);
  border: none;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date__form-wrap.show {
  max-height: 40rem;
  transition: max-height 1s cubic-bezier(0.445, 0.05, 0.24, 0.94);
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .tag-close-icon {
  transform: translateX(0);
  transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  right: 2rem;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .tag-close-icon.hide {
  transform: translateX(50px);
  display: flex;
  right: 0;
  transition: transform 1.5s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__tags {
  display: flex;
  align-items: center;
  border-radius: 72px;
  background: var(--raven-black-100, #eee);
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  padding: 0.8rem 1.2rem;
  gap: 0.8rem;
  transform: translateX(250px);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__tags.show {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__tags p {
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__tags figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__tags figure svg {
  width: 100%;
  height: 100%;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__apply-filter-btn {
  display: flex;
  width: 100%;
  padding: 2rem;
  position: absolute;
  bottom: 0;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__apply-filter-btn button {
  width: 100%;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.smartfilter .top-bar__filter-export .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}/*# sourceMappingURL=index.css.map */