.merchant_details_holder {
  display: flex;
  gap: 2rem;
}
.merchant_details_holder .merchant_first_name_innitial {
  background-color: #F7F7F7;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #020202;
  font-weight: 600;
  font-size: 1.4rem;
}/*# sourceMappingURL=cableIndex.css.map */