.nin-card {
  border-radius: 8px;
  padding: 1.6rem;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
}
.nin-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nin-card .card-header .title {
  font-size: 1.8rem;
  font-weight: 500;
}
.nin-card .card-header .revoke-button {
  background-color: rgb(255, 239, 238);
  color: rgb(255, 15, 0);
  border: none;
  padding: 1.5rem 1.6rem;
  border-radius: 1rem;
  cursor: pointer;
}
.nin-card .card-body {
  display: flex;
  margin-top: 1.6rem;
  gap: 3rem;
}
.nin-card .card-body .image-section {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  align-self: stretch;
  position: relative;
}
.nin-card .card-body .image-section .img-box {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.nin-card .card-body .image-section .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.nin-card .card-body ._contents_box {
  border: 0.1rem solid #e5e6e6;
  border-radius: 2rem;
  width: 100%;
}
.nin-card .card-body .contents_content_holder {
  display: flex;
  border-bottom: 0.1rem solid #e5e6e6;
  padding: 3rem;
  justify-content: space-between;
  align-items: flex-start;
}
.nin-card .card-body .contents_content_holder-btm {
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.nin-card .card-body ._contents_content_head {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
}
.nin-card .card-body ._contents_content_details {
  font-size: 1.4rem;
  font-weight: 400;
  color: rgb(57, 54, 54);
  align-self: flex-start;
}/*# sourceMappingURL=singlecompliance.css.map */