.bankbox-modals {
	display: flex;
	// min-height: 80rem;
	width: 100%;
	// position: absolute;
	// height: 50rem;
	// background-color: red !important;
	// flex: 1;
	overflow-y: scroll;

	&__left {
		flex: 0 0 40%;
		position: relative;
		border-radius: var(--Count, 0px);
		padding: 2.8rem;

		padding-top: 15rem;
		position: relative;

		// &--bg-absolute {
		// 	position: absolute;
		// }

		&--bg-absolute::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			flex: 1;
			bottom: 0;
			right: 0;
			z-index: -1;
			cursor: pointer;
			background-repeat: no-repeat;
			background-image: url('../../../../../../assets/bb-create-user-bg.svg');
			background-size: 100%; /* Adjust the size for the background image */
			background-position: bottom right;
		}

		&--bg-absolute::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			flex: 1;
			bottom: 0;
			right: 0;
			z-index: -2;
			cursor: pointer;
			background: linear-gradient(180deg, #f7f7f7 0%, #f7f7f7 100%);
			background-position: bottom right;
		}
		&--stepper {
			display: flex;
			flex-direction: column;
			gap: 2.4rem;

			.stepper-item {
				display: flex;
				align-items: center;
				cursor: pointer;
				gap: 0.8rem;

				&.completed {
					span {
						border-color: #014345;
						background-color: #014345;

						p {
							color: var(--base-white, var(--themelightmode, #fff));
						}
					}
				}

				&.active {
					span {
						border-color: #014345;
						background-color: #014345;

						p {
							color: var(--base-white, var(--themelightmode, #fff));
							text-align: center;
							font-size: 1.6rem;
							font-style: normal;
							font-weight: 700;
						}
					}
				}

				span {
					width: 3rem;
					height: 3rem;
					flex-shrink: 0;
					display: grid;
					place-items: center;
					border-radius: 72px;
					border: 1px solid #d1d1d1;

					p {
						color: var(--raven-black-600, #646464);
						text-align: center;
						font-size: 1.6rem;
						font-style: normal;
						font-weight: 400;
					}
				}

				h6 {
					color: var(--raven-black-600, #646464);
					text-align: center;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					// line-height: 140%; /* 16.8px */
				}
			}
		}
	}

	&__right {
		flex: 1;
		padding: 5.6rem 4.5rem;
		display: flex;
		flex-direction: column;
		// width: 100%;
		overflow-y: scroll;
		// background-color: red;

		.height-box {
			height: 6rem !important;
			width: 100%;
			background-color: red;
		}

		&--content {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;

			.basic-content {
				&__title {
					display: flex;
					flex-direction: column;
					width: 100%;
					gap: 0.8rem;

					h5 {
						color: var(--themedarkmode, #000);
						font-size: 2.4rem;
						font-style: normal;
						font-weight: 600;
					}
					h6 {
						color: var(--RAVEN-GREY, #676767);
						font-size: 3.6rem;
						font-style: normal;
						font-weight: 500;
					}
				}

				&__business-logo {
					display: flex;
					align-items: center;
					gap: 1.6rem;
					margin-top: 2rem;
					margin-bottom: 2.8rem;

					figure {
						width: 8.5rem;
						height: 8.5rem;
						border-radius: 72px;
						cursor: pointer;

						img {
							width: 100%;
							height: 100%;
							border-radius: 72px;
							object-fit: cover;
						}
					}

					p {
						color: var(--RAVEN-GREY, #676767);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 400;
						width: 40%;
					}
				}

				&__input-contain {
					display: flex;
					flex-direction: column;

					&--brand-color {
						margin-top: 3.2rem;
						display: flex;
						flex-direction: column;
						width: 100%;
						border-radius: var(--Count, 0px);

						.selector-title {
							width: 100%;
							display: flex;
							align-items: center;
							margin-bottom: 1.6rem;
							padding-bottom: 0.6rem;
							border-bottom: 1px solid var(--raven-black-200, #e3e3e3);

							p {
								color: var(--themedarkmode, #000);
								font-size: 1.4rem;
								font-style: normal;
								font-weight: 400;
								line-height: 140%; /* 19.6px */
							}
						}

						.color-selector {
							display: flex;
							align-items: center;
							gap: 1.6rem;
							margin-bottom: 4.8rem;

							.bColors {
								width: 5rem;
								height: 5rem;
								display: grid;
								place-items: center;
								border-radius: 72px;
								cursor: pointer;

								&.active {
									border: 2px solid;
								}
							}
						}
					}
				}
			}

			.price-content {
				&__selected-more-details-box {
					width: 100%;
					margin-top: -3rem;
					margin-bottom: 2.5rem;
					display: flex;
					// gap: 2rem;
					// padding-top: 2rem;
					border-width: 0.15rem;
					border-radius: 1.2rem;

					// &>*:first-child{
					// 	padding-top: 2rem;
					// }

					& > * {
						// padding: 2rem 0rem;
						gap: 2rem;
						display: flex;
						flex-direction: column;
					}

					.label-boxes {
						flex: 0 0 30%;
						// margin-right: 2rem;
						border-width: 0.15rem;
						// padding-left: 2rem;

						.label {
							border-width: 0.15rem;
							padding: 2rem;
						}
					}

					.value-boxes {
						flex: 1;
						display: flex;
						flex-direction: column;
						// height: max-content;

						.value {
							border-width: 0.15rem;
							font-weight: 600;
							padding: 2rem;
							// font-size: 1;
						}
					}
				}
				&__input-contain {
					margin-top: 2rem;
					margin-bottom: 4.8rem;
					display: flex;
					flex-direction: column;
					gap: 2.4rem;

					&--grouped {
						display: flex;
						gap: 1.6rem;
					}
				}
			}

			.left-right-box-wrap {
				flex: 1;
				display: flex;
				overflow-y: scroll;
				margin-top: 2rem;
				gap: 1rem;
				padding: 0rem 1rem;
				border-width: 0.15rem;
				border-radius: 1.2rem;

				& > * {
					padding-top: 1rem;
					padding-bottom: 1rem;
				}

				.left-box {
					flex: 0 0 40%;
					border-width: 0.2rem;
					padding-right: 1rem;
					display: flex;
					flex-direction: column;

					.module-list-box {
						border-radius: 1.2rem;
						padding: 2rem;
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 3rem;

						.name {
							font-size: 1.45rem;
							text-transform: capitalize;
						}

						.item-row {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.toggle-name-box {
								transform: scale(0.8);
							}
						}
					}
				}

				.right-box {
					margin-top: 1.5rem;
					flex: 1;
					overflow-y: scroll;
					display: flex;
					flex-direction: column;
					gap: 3rem;
					padding-left: 2rem;

					.title-permissions-box {
						display: flex;
						flex-direction: column;
						gap: 1.5rem;

						.module-title {
							font-weight: 500;
							font-size: 1.8rem;
							text-transform: capitalize;
						}

						.permission-box {
							display: flex;
							flex-direction: column;
							gap: 1.5rem;

							.permission-row {
								display: flex;
								align-items: center;
								gap: 1rem;
								border-radius: 0.8rem;
								padding: 1.5rem;
								border-width: 0.15rem;

								.permission-row-label {
									display: flex;
									align-items: center;
									gap: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

.bankbox-create-setup-modal {
	.raven-modal-content-wrap {
		max-height: unset;
		.content {
			// height: 95vh;

			.bankbox-modals {
				display: flex;
				// flex: 1;
				width: 100%;
				min-height: 75rem;
				max-height: 95vh !important;
				overflow-y: scroll;
				// padding-bottom: 2rem;

				&__left {
					flex: 0 0 40%;
					position: relative;
					border-radius: var(--Count, 0px);
					padding: 2.8rem;

					padding-top: 15rem;
					position: relative;

					// &--bg-absolute {
					// 	position: absolute;
					// }

					&--bg-absolute::before {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						flex: 1;
						bottom: 0;
						right: 0;
						z-index: -1;
						cursor: pointer;
						background-repeat: no-repeat;
						background-image: url('../../../../../../assets/bb-create-user-bg.svg');
						background-size: 100%; /* Adjust the size for the background image */
						background-position: bottom right;
					}

					&--bg-absolute::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						flex: 1;
						bottom: 0;
						right: 0;
						z-index: -2;
						cursor: pointer;
						background: linear-gradient(180deg, #f7f7f7 0%, #f7f7f7 100%);
						background-position: bottom right;
					}
					&--stepper {
						display: flex;
						flex-direction: column;
						gap: 2.4rem;

						.stepper-item {
							display: flex;
							align-items: center;
							cursor: pointer;
							gap: 0.8rem;

							&.completed {
								span {
									border-color: #014345;
									background-color: #014345;

									p {
										color: var(--base-white, var(--themelightmode, #fff));
									}
								}
							}

							&.active {
								span {
									border-color: #014345;
									background-color: #014345;

									p {
										color: var(--base-white, var(--themelightmode, #fff));
										text-align: center;
										font-size: 1.6rem;
										font-style: normal;
										font-weight: 700;
									}
								}
							}

							span {
								width: 3rem;
								height: 3rem;
								flex-shrink: 0;
								display: grid;
								place-items: center;
								border-radius: 72px;
								border: 1px solid #d1d1d1;

								p {
									color: var(--raven-black-600, #646464);
									text-align: center;
									font-size: 1.6rem;
									font-style: normal;
									font-weight: 400;
								}
							}

							h6 {
								color: var(--raven-black-600, #646464);
								text-align: center;
								font-size: 1.2rem;
								font-style: normal;
								font-weight: 400;
								// line-height: 140%; /* 16.8px */
							}
						}
					}
				}

				&__right {
					flex: 1;
					padding: 5.6rem 4.5rem;
					display: flex;
					flex-direction: column;
					// width: 100%;
					overflow-y: scroll;

					&--content {
						display: flex;
						flex-direction: column;
						width: 100%;
						height: 100%;
						// padding-bottom: 5rem !important;

						.basic-content {
							&__title {
								display: flex;
								flex-direction: column;
								width: 100%;
								gap: 0.8rem;
								// padding-bottom: 5rem !important;

								h5 {
									color: var(--themedarkmode, #000);
									font-size: 2.4rem;
									font-style: normal;
									font-weight: 600;
								}
								h6 {
									color: var(--RAVEN-GREY, #676767);
									font-size: 3.6rem;
									font-style: normal;
									font-weight: 500;
								}
							}

							&__business-logo {
								display: flex;
								align-items: center;
								gap: 1.6rem;
								margin-top: 2rem;
								margin-bottom: 2.8rem;

								figure {
									width: 8.5rem;
									height: 8.5rem;
									border-radius: 72px;
									cursor: pointer;

									img {
										width: 100%;
										height: 100%;
										border-radius: 72px;
										object-fit: cover;
									}
								}

								p {
									color: var(--RAVEN-GREY, #676767);
									font-size: 1.4rem;
									font-style: normal;
									font-weight: 400;
									width: 40%;
								}
							}

							&__input-contain {
								display: flex;
								flex-direction: column;

								&--brand-color {
									margin-top: 3.2rem;
									display: flex;
									flex-direction: column;
									width: 100%;
									border-radius: var(--Count, 0px);

									.selector-title {
										width: 100%;
										display: flex;
										align-items: center;
										margin-bottom: 1.6rem;
										padding-bottom: 0.6rem;
										border-bottom: 1px solid var(--raven-black-200, #e3e3e3);

										p {
											color: var(--themedarkmode, #000);
											font-size: 1.4rem;
											font-style: normal;
											font-weight: 400;
											line-height: 140%; /* 19.6px */
										}
									}

									.color-selector {
										display: flex;
										align-items: center;
										gap: 1.6rem;
										margin-bottom: 4.8rem;

										.bColors {
											width: 5rem;
											height: 5rem;
											display: grid;
											place-items: center;
											border-radius: 72px;
											cursor: pointer;

											&.active {
												border: 2px solid;
											}
										}
									}
								}
							}
						}

						.price-content {
							&__form-submit {
								padding-bottom: 4rem;
							}
							&__selected-more-details-box {
								width: 100%;
								margin-top: -3rem;
								margin-bottom: 2.5rem;
								display: flex;
								// gap: 2rem;
								// padding-top: 2rem;
								border-width: 0.15rem;
								border-radius: 1.2rem;

								// &>*:first-child{
								// 	padding-top: 2rem;
								// }

								& > * {
									// padding: 2rem 0rem;
									gap: 2rem;
									display: flex;
									flex-direction: column;
								}

								.label-boxes {
									flex: 0 0 30%;
									// margin-right: 2rem;
									border-width: 0.15rem;
									// padding-left: 2rem;

									.label {
										border-width: 0.15rem;
										padding: 2rem;
									}
								}

								.value-boxes {
									flex: 1;
									display: flex;
									flex-direction: column;
									// height: max-content;

									.value {
										border-width: 0.15rem;
										font-weight: 600;
										padding: 2rem;
										// font-size: 1;
									}
								}
							}
							&__input-contain {
								margin-top: 2rem;
								margin-bottom: 4.8rem;
								display: flex;
								flex-direction: column;
								gap: 2.4rem;

								&--grouped {
									display: flex;
									gap: 1.6rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

.bankbox-modals__left-two {
	flex: 0 0 25% !important;
}
