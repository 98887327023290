.team-settings-index-wrap-box {
	flex: 1;
	// background-color: red;
	display: flex;
	flex-direction: column;

	.top-select-box {
		display: flex;
		align-items: center;
		gap: 2rem;

		.smartfilter {
			margin-bottom: 0rem;
		}

		.btn {
			min-width: 15rem;
		}

		.settings-pagination-box {
			width: max-content;
			// background-color: red;
			display: flex;
			align-items: center;
			padding: 1rem;
			border-width: 0.15rem;
			border-radius: 0.8rem;
			align-self: stretch;

			& > * {
				width: max-content;
			}
		}
	}

	.table-setting-body {
		margin-top: 2rem;

		.pending-row {
			& > *:first-child,
			& > *:nth-child(2),
			& > *:nth-child(3) {
				opacity: 0.4;
			}
		}
	}
}
