.transaction-status {
	&.delivered{
		background-color: #e8fff6;
		text-transform: capitalize;

		p {
			color: #1ace37;
		}
	}

	&.approved {
		background-color: #e8fff6;
		text-transform: capitalize;

		p {
			color: #1ace37;
		}
	}

	&.declined {
		background-color: #fff5f5;
		p {
			color: #ff0f00;
		}
	}
}
