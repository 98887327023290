:root {
	--camera-control-offset: 14rem;
}

.webcam-wrapper {
	position: absolute;
	inset: 0;
	background-color: transparent;
	--sheet-height: var(--camera-control-offset);
	// background-color: blue;
	top: 0%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translateX(-50%) translateY(0%);
}
.webcam-wrapper #video-player {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

  &::after{
    display: none;
    position: absolute;
    content: '';
    width: 90%;
    height: 50%;
    border: .15rem solid #1ACE37;
    border-radius: .5rem;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%) translateY(-40%);
  }
}

.webcam-wrapper .camera-controls {
	position: absolute;
	z-index: 10;
	// background-color: red;
	border: none;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.6rem;
	height: var(--camera-control-offset);
  display: none;
}
.webcam-wrapper .camera-controls button {
	border: none;
	background-color: transparent;
	height: 6rem;
	width: 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 10;
	cursor: pointer;
}
.webcam-wrapper .camera-controls:hover {
	background-color: transparent;
}
.webcam-wrapper .preview-controls {
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	gap: 1rem;
}
.webcam-wrapper .preview-controls button {
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 1rem;
	color: #fff;
	white-space: nowrap;
	width: auto;
}
.webcam-wrapper .preview-controls button:last-of-type {
	justify-content: end;
}

.webcam-wrapper .capture-id-mask {
	position: absolute;
	inset: 0;
	max-height: calc(100% - var(--camera-control-offset));
	display: grid;
	place-items: center;

	&::before {
		// border: 1px solid rgba(0, 0, 0, 0.1);
		// border-width: 1000rem;
		position: absolute;
		inset: 0;
		content: '';
	}
}

.capture-id-mask img {
	height: 40rem;
	mix-blend-mode: difference;
  // display: none;
}

.sdk-snapped-image {
	position: fixed;
	inset: 0;
	// background-color: red;
  z-index: 20;
	height: 100%;
	width: 100%;
	// display: none;

	.img-div {
		width: 100%;
		height: 100%;
    position: relative;
		// background-color: red;

		.img-box {
			width: 100%;
			height: 100%;

			.img {
				width: 100%;
				height: 100%;
				object-fit: cover;
        // background-color: blue;
			}
		}
	}
}

// .sdk-snapped-image img {
// 	object-fit: cover;
// 	height: 100%;
// 	width: 100%;
// }

.sdk-snapped-image .camera-controls {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	padding: 0 3rem;
  display: none;

	svg {
		width: 2.4rem;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 1rem;

		span {
			white-space: nowrap;
		}
	}

	.refresh-btn {
		color: #fff;
		width: fit-content;
		margin-right: auto;
	}

	.accept-btn {
		width: 100%;
		color: #1ace37;
		margin-left: auto;
		width: fit-content;
	}
}

.camera-flash-scan {
	position: absolute;
	inset: 0;
	--scan-line-height: 10rem;
	--scan-offset-height: calc(
		var(--camera-control-offset) + var(--scan-line-height) - 1rem
	);

	&::before {
		position: absolute;
		width: 100%;
		height: var(--scan-line-height);
		animation: ScanLights .5s linear 0.5s infinite;
		content: '';
		background-image: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.4),
			rgba(255, 255, 255, 0)
		);
	}

	&::after {
		position: absolute;
		inset: 0;
		background-color: rgba(0, 0, 0, 0.4);
		content: '';
	}
}

@keyframes ScanLights {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(35rem);
	}
}

.sdk-scanned-result {
	position: fixed;
	inset: 0;
	background-color: #fff;
	z-index: 11;

	& > * {
		display: block;
		margin-bottom: 0.4rem;
	}

	button {
		margin-top: 2rem;
	}
}

.webcam-wrapper #video-player canvas {
	max-height: 100%;
  
}
.webcam-wrapper #video-player video {
	position: absolute;
	inset: 0;
	max-height: 100%;
	width: 100%;
	-o-object-fit: cover !important;
	object-fit: cover !important;
}
