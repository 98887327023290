

.create-fee-category-modal-wrap-index{
  .title-text-box{
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: .3rem;

    .title{
      font-size: 2.2rem;
      font-weight: 700;
    }

    .text{
      font-size: 1.3rem;
    }
  }

  .step-content-wrap{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 2.5rem;
    min-height: 35rem;

    .value-label-wrap-index-box {
			display: flex;
			flex-direction: column;
			border-radius: 1.2rem;

			.label-value-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0rem 1.5rem;
				// padding-b: unset;

				.label {
					font-weight: 600;
				}

				.value {
					text-align: right;
					flex: 0 0 65%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					text-align: right;
				}

				.copy-wrap-index {
					display: flex;
					align-items: center;
					// justify-content: space-evenly;
					gap: 0.8rem;

					// .copy-box {
					// 	display: grid;
					// 	place-items: center;
					// 	border-radius: 50%;
					// 	width: 3rem;
					// 	height: 3rem;
          //   position: relative;

					// 	.img-box {
					// 		width: 100%;
					// 		height: 100%;
					// 		cursor: pointer;
          //     position: relative;

					// 		.img {
					// 			width: 100%;
					// 			height: 100%;
					// 			object-fit: contain;
					// 		}
					// 	}
					// }
				}
			}

			& > *:not(:last-child) {
				padding-bottom: 2rem;
				// margin-bottom: 1.5rem;
			}
		}

    .badge-info-box {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: max-content;
      // margin-bottom: 2rem;

      .bagde-box {
        padding: 0.8rem 1.5rem;
        border-radius: 2.5rem;

        // span {
        // 	// font-weight: 600;
        // }
      }

      .info-box {
        display: grid;
        place-items: center;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;

        .img-box {
          width: 100%;
          height: 100%;
          cursor: pointer;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .step-content-wrap-no-height{
    min-height: unset;
  }

  .grey-step-wrap-box{
    display: flex;
    align-items: center;
    padding: .85rem 1.5rem;
    border-radius: 3rem;
    width: max-content;
    gap: 1rem;

    .step{
      min-height: 1.2rem;
      min-width: 2.5rem;
      background-color: #E3E3E3;
      border-radius: 4rem;
      cursor: pointer;
    }

    .step-long{
      min-width: 5.5rem;
    }

    .step-active{
      background-color: #014345;
    }
  }
}