.forgot-password {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;

	.forgot-password__logo {
		width: 100%;
		display: flex;
		gap: 1rem;
		justify-content: center;

		h5 {
			font-weight: 900;
			font-size: 20px;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: var(--primary-blue);
		}
	}

	.forgot-password__body-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 10.3rem;

		.body-wrap__back-to-login {
			display: flex;
			gap: 1rem;
			align-items: center;
			cursor: pointer;

			&:hover {
				figure {
					transform: translateX(-3px);
				}
			}

			figure {
				width: fit-content;
				height: fit-content;
				transition: transform 300ms ease-in-out;
			}
			p {
				font-weight: 700;
				font-size: 16px;
				line-height: 140%;
				display: flex;
				align-items: center;
				color: #755ae2;
			}
		}

		.body-wrap__main-content {
			background: #ffffff;
			width: 100%;
			border: 1px solid #e9f5ff;
			border-radius: 1.6rem;
			flex: none;
			padding: 4.8rem 2.4rem;
			margin-top: 3.7rem;

			h5 {
				font-weight: 700;
				font-size: 24px;
				line-height: 140%;
				display: flex;
				align-items: center;
				color: #000000;
			}

			span {
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 160%;
				color: #646464;
				display: flex;
				margin-top: 0.6rem;
			}

			.main-content__form {
				display: flex;
				flex-direction: column;
				margin-top: 3.6rem;
				gap: 7.6rem;
			}
		}
	}

	.new-password__body-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 10.3rem;

		.body-wrap__main-content {
			background: #ffffff;
			width: 100%;
			border: 1px solid #e9f5ff;
			border-radius: 1.6rem;
			flex: none;
			padding: 4.8rem 2.4rem;
			margin-top: 3.7rem;

			h5 {
				font-weight: 700;
				font-size: 24px;
				line-height: 140%;
				display: flex;
				align-items: center;
				// color: #000000;
			}

			span {
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 160%;
				// color: #000000;
				display: flex;
				margin-top: 0.6rem;
			}

			.main-content__form {
				display: flex;
				flex-direction: column;
				margin-top: 3.6rem;
				gap: 2.8rem;

				.form__c2a {
					margin-top: 11.9rem;
				}
			}
		}
	}
}
