.badge-reusable-wrap-index {
	.badge-wrap-index {
		padding: 0.65rem 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center !important;
		background-color: #fff6ed;
		cursor: pointer;
		border-radius: 3.5rem;
		width: max-content;
		gap: .5rem;

		span {
			font-weight: 500;
			font-size: 1.3rem;
			color: #ea872d;
      text-transform: capitalize;
      text-align: center !important;
      width: unset !important;
		}

		.no-capitalize{
			text-transform: lowercase;
		}
	}

	.pending {
		background-color: #fff6ed;

		span {
			color: #ea872d;
		}
	}

	.info {
		background-color: #e9f5ff;

		span {
			color: #476885;
		}
	}

	.purple {
		background-color: rgba(240, 238, 255, 1);

		span{
			color: rgba(117, 90, 226, 1);
		}
	}

	.failed {
		background-color: #fff5f5;

		span {
			color: #ff0f00;
		}
	}

	.success {
		background-color: #e8fff6;

		span {
			color: #1ACE37;
		}
	}
}
