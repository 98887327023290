.camera-assign-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.camera-assign-box-wrap .text {
  font-size: 1.25rem;
  color: #676767;
}
.camera-assign-box-wrap .text span {
  color: #014345;
  font-weight: 600;
}
.camera-assign-box-wrap .capture-note-box {
  display: flex;
  gap: 0.5rem;
  border-radius: 1.2rem;
  padding: 1rem;
  background-color: rgb(255, 246, 237);
  margin: -2rem 0rem 2rem 0rem;
}
.camera-assign-box-wrap .capture-note-box .img-note .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.camera-assign-box-wrap .capture-note-box .img-note .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.camera-assign-box-wrap .capture-note-box .note {
  font-size: 1.25rem;
  font-weight: 500;
}
.camera-assign-box-wrap .capture-note-box .note span {
  color: #014345;
  font-weight: 700;
}
.camera-assign-box-wrap .camera-wrap-box {
  height: 17rem;
  background-color: transparent;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 4rem;
}
.camera-assign-box-wrap .two-btn-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  width: 100%;
  margin-top: auto;
}
.camera-assign-box-wrap .two-btn-box > * {
  width: 100%;
}
.camera-assign-box-wrap .two-btn-box .cancel-btn {
  background-color: #f7f7f7;
  color: #020202;
  font-weight: 700;
  text-transform: capitalize;
}/*# sourceMappingURL=CameraAssignBox.css.map */