.input_filter_export_general_wrap {
	display: flex;
	gap: 1rem;
	// align-items: center;
	.pagination-wrap {
		width: 15rem;
		margin-top: 1.5rem;
		.top-bar__pagination {
			display: flex;
		}
	}
}
.fee-detail-dashboard-content-index-wrap {
	.table__main {
		.status_and_modal_holder {
			display: flex;
			padding-right: 2rem;
			align-items: center;
			justify-content: space-between;
		}
	}
}
