.transaction-status.delivered {
  background-color: #e8fff6;
  text-transform: capitalize;
}
.transaction-status.delivered p {
  color: #1ace37;
}
.transaction-status.approved {
  background-color: #e8fff6;
  text-transform: capitalize;
}
.transaction-status.approved p {
  color: #1ace37;
}
.transaction-status.declined {
  background-color: #fff5f5;
}
.transaction-status.declined p {
  color: #ff0f00;
}/*# sourceMappingURL=index.css.map */