.bankbox-user-view {
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	background: var(--themelightmode, #fff);
	padding: 0 1.7rem;
	// background-color: green;

	&__left {
		display: flex;
		padding-top: 2.7rem;
		flex-direction: column;
		// background-color: blue;

		// flex: 1;
		// height: 100%;

		width: 70%;
		border-right: 1px solid var(--raven-black-100, #eee);
		overflow-y: scroll;
		padding-bottom: 3rem;

		&--title {
			display: flex;
			flex-direction: row;
			gap: 0.2rem;
			padding-bottom: 2rem;
			padding-right: 2rem;

			.left {
				display: flex;
				flex-direction: column;
				gap: 0.2rem;
				margin-right: auto;
			}

			h6 {
				color: var(--themedarkmode, #000);
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 700;
				line-height: 140%; /* 33.6px */
			}

			p {
				color: var(--RAVEN-GREY, #676767);
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
			}
		}

		&--date-location {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 1.2rem 0;
			border-top: 1.5px solid var(--raven-black-100, #eee);
			border-bottom: 1.5px solid var(--raven-black-100, #eee);
			gap: 3.2rem;

			.item {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1.2rem;

				figure {
					display: grid;
					width: 4.5rem;
					height: 4.5rem;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				p {
					color: var(--themedarkmode, #000);
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 19.6px */
				}
			}
		}

		&--user-details {
			display: flex;
			flex-direction: column;
			gap: 1.8rem;
			width: 100%;
			padding-right: 2.3rem;
			margin-top: 2.5rem;
			// overflow-y: scroll;
			// height: calc(100vh - 30rem);
			padding-bottom: 2rem;
			// background-color: red;

			.details-row {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 2.4rem;

				.details {
					// flex: 1 1 0;
					// display: flex;
					display: flex;
					padding: 2.4rem 1.2rem;
					flex-direction: column;
					align-items: flex-start;
					gap: 1rem;

					.bank-name-box {
						display: flex;
						align-items: center;
						gap: 1rem;

						.name {
							font-weight: 600;
						}

						.grey-white-color {
							font-size: 1.2rem;
						}

						.icon-wrap {
							border-width: 0.15rem;
						}

						.img-box {
							width: 4rem;
							height: 4rem;
							border-radius: 50%;

							.img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
					}

					border-radius: 8px;
					border: 1px solid var(--raven-black-100, #eee);

					span {
						color: var(--base-black-base-bg-dark, #676767);
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 500;
					}

					p {
						color: var(--base-black-base-background-dark, #020202);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 19.6px */
					}
				}
			}
		}

		.fee-details-info-box {
			width: 100%;
			flex: 1;
			// background-color: red;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding-right: 2rem;
			margin-top: 2rem;

			.title-change-box {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 1.5rem;
				border-width: 0.15rem;

				.title,
				.change {
					font-weight: 600;
					font-size: 1.55rem;
				}

				.change {
					color: #014345;
					cursor: pointer;
				}
			}

			.detail-box {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				flex-wrap: wrap;
				grid-gap: 2rem;
				margin-top: 1.5rem;

				& > *:not(:last-child) {
					padding-right: 2rem;
					// border-right: none !important;
				}

				.label-value-box {
					display: flex;
					flex-direction: column;
					border-width: 0.15rem;
					// width: max-content;
					gap: 0.5rem;
					border-width: 0.15rem;

					.label {
						font-size: 1.3rem;
					}

					.value {
						font-size: 1.55rem;
						font-weight: 600;
					}
				}
			}
		}
	}

	&__right {
		display: flex;
		width: 30%;
		height: 100%;
		flex-direction: column;

		&--title {
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// gap: 1.2rem;
			padding: 1.6rem;
			border-radius: var(--Count, 0px);
			border-bottom: 1px solid var(--raven-black-100, #eee);
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 2rem;

			figure {
				display: grid;
				width: 4.5rem;
				height: 4.5rem;
				place-items: center;

				svg {
					width: 100%;
					height: 100%;
				}
			}

			h6 {
				color: var(--themedarkmode, #000);
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 22.4px */
			}

			.select-item-wrap {
				padding: 1.5rem 2.5rem;
				border-radius: 0.8rem;
				display: flex;
				justify-content: center;
				cursor: pointer;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					top: 130%;
					left: 0%;
					height: 0.5rem;
					width: 100%;
					background-color: #014345;
					border-radius: 5rem;
					visibility: hidden;
				}
			}

			.select-item-wrap-active {
				cursor: unset;
				span {
					color: #014345;
					font-weight: 600;
				}

				&::after {
					visibility: visible;
				}
			}
		}

		&--devices-contain {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			height: calc(100vh - 25rem);
			overflow-y: auto;
			padding: 2.4rem 1.6rem;

			.request-box-wrap-card {
				padding: 1.5rem;
				border-radius: 0.8rem;

				.box-wrap-card {
					padding: 2rem;
					border-radius: 0.8rem;

					.top-box {
						display: flex;
						justify-content: space-between;

						.quantity-ref-box {
							.quantity {
								font-size: 1.3rem;
								margin-bottom: 0.5rem;
							}
							.ref {
								font-weight: 600;
								font-size: 1.5rem;
							}
						}
					}

					.bottom-box {
						margin-top: 1.5rem;
						padding-top: 1.5rem;
						display: flex;
						align-items: center;
						gap: 1.5rem;

						.user-img-box {
							width: 3.5rem;
							height: 3.5rem;
							display: grid;
							place-items: center;
							border-radius: 50%;

							.img-box {
								width: 60%;
								height: 60%;

								.img {
									object-fit: contain;
									width: 100%;
									height: 100%;
								}
							}
						}

						.email {
							font-size: 1.25rem;
						}
					}
				}
			}

			.empty_all_bb {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				h6 {
					font-size: 2rem;
					font-style: normal;
					font-weight: 600;
					color: #014345;
				}

				p {
					color: var(--RAVEN-GREY, #676767);
					text-align: center;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 400;
					line-height: 160%; /* 25.6px */
				}
			}

			.device {
				display: flex;
				border-radius: 8px;
				flex-direction: column;
				background: var(--raven-black-50, #f7f7f7);
				padding: 2rem 1.2rem;
				background-image: url('../../../../../assets/images/bank-box.png');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: bottom right;
				position: relative;

				.badge-user-box {
					position: absolute;
					top: 1rem;
					right: 1rem;
					// background-color: red;
				}

				&__business {
					display: flex;
					flex-direction: column;

					h6 {
						color: var(--raven-black-800, #333);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 700;
						line-height: 140%; /* 19.6px */
					}

					p {
						color: var(--raven-black-600, #646464);
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 16.8px */
					}
				}

				&__owner {
					display: inline-flex;
					padding: 0.8rem;
					flex-direction: column;
					align-items: flex-start;
					gap: 1.4rem;
					border-radius: 10px;
					margin-top: 1.8rem;
					background: var(--themelightmode, #fff);

					&--email {
						display: flex;
						align-items: center;
						gap: 0.4rem;

						figure {
							display: grid;
							width: 2.5rem;
							height: 2.5rem;
							place-items: center;

							svg {
								width: 100%;
								height: 100%;
							}
						}

						p {
							color: var(--raven-black-600, #646464);
							font-size: 1rem;
							font-style: normal;
							font-weight: 500;
							line-height: 140%; /* 14px */
						}
					}

					&--trx-volume {
						display: flex;
						flex-direction: column;

						p {
							color: var(--raven-black-500, #8b8b8b);
							font-size: 1rem;
							font-style: normal;
							font-weight: 400;
							line-height: 140%; /* 14px */
						}

						h6 {
							color: var(--raven-black-800, #333);
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 600;
							line-height: 140%; /* 19.6px */
						}
					}
				}
			}
		}
	}
}

.bankbox-assign-btn {
	color: var(--primary-darkgreen-100, #014345);
	// font-size: 1.6rem;
	font-style: normal;
	font-weight: 600;
	background: none;
	border-radius: 10px;
	border: 1px solid var(--raven-grey-shade-50, #ccc);
}

.assign-bb-modal {
	display: flex;
	flex-direction: column;

	.css-1nmdiq5-menu {
		width: 98%;
		left: 50%;
		transform: translateX(-50%);
	}

	.amount-show-box {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.text-amount- {
			font-size: 1.25rem;
			// font-weight: 600;
			// color: #333333;
			span {
				color: unset !important;
				// font-weight: 600;
			}
		}

		.img-box {
			width: 1.5rem;
			height: 1.5rem;

			.img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.settle-ment-account-wrap {
		display: flex;
		flex-direction: column;

		.label {
			margin-bottom: 0.4rem;
			font-size: 1.3rem;
			color: #020202;
		}

		.settle-ment-account-box {
			padding: 1.5rem;
			border-radius: 1.2rem;
			display: flex;
			align-items: center;
			gap: 1.5rem;

			.name-account-num-box {
				display: flex;
				flex-direction: column;
				gap: 0.3rem;
				.name {
					font-size: 1.35rem;
				}
				.acc-num {
					font-size: 2rem;
					font-weight: 700;
				}
			}

			.icon-wrap-box {
				.icon-wrap {
					width: 4.5rem;
					height: 4.5rem;
					border-radius: 50%;
					border-width: 0.15rem;

					.img-box {
						width: 100%;
						height: 100%;

						.img {
							object-fit: contain;
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}
					}
				}
			}
		}
	}

	.two-input-field-box {
		// min-height: 20rem;
		// background-color: red;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 2rem;

		@media only screen and (max-width: 48em) {
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
	}

	.camera-icon-box {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		width: max-content;
		// margin-bottom: 2rem;
		cursor: pointer;

		// .text {
		// 	font-size: 1.25rem;
		// 	color: #676767;
		// }

		.camera-icon {
			display: flex;
			align-items: center;

			.img-box {
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				display: grid;
				place-items: center;

				.img {
					width: 60%;
					height: 60%;
					object-fit: contain;
				}
			}
		}
	}

	.auto-generate-box-wrap {
		display: flex;
		align-items: center;
		gap: 0.7rem;

		.text-white-light {
			color: #ffffff !important;
		}

		.info-box {
			.img-box {
				width: 1.5rem;
				height: 1.5rem;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.togle-info-box {
			transform: scale(0.8);
		}
	}

	h6 {
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
	}
	p {
		color: #676767;
	}
	&__title {
		display: flex;
		flex-direction: column;
		// gap: 1.4rem;
		margin-bottom: 2.8rem;
	}
	&__form-body {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
		// min-height: 35rem;
		max-height: 45rem;
		overflow-y: scroll;

		.auto-assign-content-box {
			// margin-top: 2.5rem;
			.export-modal {
				display: flex;
				flex-direction: column;
				width: 100%;
				height: 100%;
				flex: 1;

				&__title {
					display: flex;
					flex-direction: column;

					h5 {
						font-size: 2rem;
						font-style: normal;
						font-weight: 600;
						line-height: 160%; /* 32px */
					}

					p {
						color: var(--raven-base-bg-dark, #676767);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 19.6px */
					}
				}

				&__export-as {
					display: flex;
					flex-direction: column;
					margin-top: 2.5rem;
				}

				&__export-as--selector-wrap {
					display: flex;
					gap: 1.6rem;
					margin-bottom: 2.8rem;

					.selector-wrap__item {
						display: flex;
						height: 6rem;
						padding: 0.8rem 1.2rem;
						align-items: center;
						gap: 12px;
						flex: 1 0 0;
						border-radius: 1.2rem;
						border: 1px solid var(--raven-black-100, #eee);
						background: var(--base-white, #fff);
						margin-top: 1.2rem;
						cursor: pointer;

						&.selected {
							border: 2px solid var(--primary-darkgreen-100, #014345);
							background: var(--raven-black-50, #f7f7f7);
						}
					}
				}

				&__days-selector {
					display: flex;
					flex-direction: column;

					.days-selector__item-wrap {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						gap: 1.2rem;
						padding: 2rem 0;

						.days-selector__item {
							display: flex;
							padding: 0.8rem 1.2rem;
							justify-content: center;
							align-items: center;
							gap: 10px;
							border-radius: 72px;
							cursor: pointer;
							background: var(--raven-black-50, #f7f7f7);

							&.selected {
								border-radius: 72px;
								border: 1.4px solid var(--primary-darkgreen-100, #014345);
								background: var(--raven-black-100, #eee);

								figure svg path {
									stroke: #014345;
								}

								figure {
									border-radius: 50rem;
									width: min-content;
									height: min-content;
									display: grid;
									place-items: center;
									overflow: hidden;
								}

								p {
									color: var(--primary-darkgreen-100, #014345);
									text-align: center;
									font-size: 1.2rem;
									font-style: normal;
									font-weight: 600;
									line-height: 140%; /* 16.8px */
								}
							}

							p {
								color: var(--raven-black-500, #8b8b8b);
								text-align: center;
								font-size: 1.2rem;
								font-style: normal;
								font-weight: 400;
								line-height: 140%; /* 16.8px */
							}
						}
					}
				}

				&__custom-date {
					display: flex;
					flex-direction: row;
					gap: 2.4rem;
					align-items: center;
					margin-bottom: 4.9rem;
					max-height: 0;
					overflow: hidden;
					transition: max-height 2s ease-in-out;

					&.show {
						max-height: 400rem;
						transition: max-height 2s ease-in-out;
					}
				}
			}
		}
	}
}

.serial-number-input-form-group {
	position: relative;
	width: 100%;
	// background-color: red;

	.text-white-light {
		color: #ffffff !important;
	}

	.upload-toggle-box {
		position: absolute;
		top: 37%;
		right: 1rem;
		width: 4rem;
		height: 4rem;
		z-index: 10;
		border-width: 0.15rem;
		border-radius: 50%;
		display: grid;
		place-items: center;
		cursor: pointer;

		&:hover {
			.img-box {
				transform: scale(1.05);
			}
		}

		.img-box {
			width: 50%;
			height: 50%;
			transition: transform 0.3s ease-in-out;

			.img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}



.test{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
	grid-gap: 1.5rem;
}