.cards-d {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.mode.modal-parent-wrap .raven-modal-content-wrap {
	width: 34% !important;
}

.icon-arrow {
	display: flex;
	margin-left: 1rem;
}

.group {
	display: flex;
	align-items: center;
}
.img- {
	display: flex;
	margin-left: 1rem;
	background-color: rgba(255, 246, 237, 1);
	padding: 1rem;
	border-radius: 100%;
}

.my_custom {
	margin-top: 2rem !important;
	font-size: 1.6rem !important;
	color: rgba(0, 0, 0, 1);
	margin-bottom: 1rem;
}

.card-details-button {
	background-color: rgba(247, 248, 247, 1);
	border: none;
	border-radius: 16px;
	padding: 6px 12px;
	font-weight: 500;
	color: #333;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.2s ease, color 0.2s ease;
}

.tabs-navigation {
	background-color: rgba(249, 251, 252, 1);
	display: flex;
	border-radius: 1rem;
	justify-content: center;
	gap: 4rem;
	padding: 1.2rem;
}

.tab-btn {
	border-radius: 1rem;
	background-color: transparent;
	border: 1px solid #ccc;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	cursor: pointer;
	color: rgba(100, 100, 100, 1);
	border: none;
}

.tab-btn.active {
	background-color: rgba(255, 255, 255, 1);
	color: rgba(1, 67, 69, 1);
	border: none;
	font-weight: 600;
}

.tab-content {
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f9f9f9;
}
