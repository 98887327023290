.verification {
	width: 100vw;
	height: 100vh;
	color: black;
	display: flex;
	flex-direction: column;
	flex: column;
	position: relative;
	background-color: #f9fafb;

	.verification__header {
		display: flex;
		background-color: white;
		padding: 2rem 7rem;
		width: 100%;
		align-items: center;
		gap: 1.2rem;
		align-items: center;
		justify-content: flex-start;

		.logo-text-box {
			// padding: 0rem 2rem;
			// align-self: center;
			display: flex;
			align-items: center;
			height: 100%;
			.text {
				color: var(--raven-black-700, #515151);
				font-weight: 600;
				font-size: 1.35rem;
				height: 100%;
				display: flex;
				align-self: center;
				place-items: center;
			}

			.logo-box {
				margin-right: 1.2rem;
				padding-right: 1.2rem;
				height: 100%;
				align-items: center;
				display: flex;
				align-items: center;
				align-self: center;

				.img-box {
					width: 10rem;
					height: min-content;
					display: flex;
					align-items: center;

					.img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
	}

	.verification__children {
		max-width: 40%;
		width: 100%;
		max-height: 75%;
		margin-top: 6.7rem;
		height: 100%;
		align-self: center;
		// background-color: red;
	}

	.verification__footer {
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 3rem 6.4rem;

		.footer__left {
			display: flex;
			gap: 1.2rem;

			p {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 140%;
				color: #1b1b1b;
			}
		}

		.footer__right {
			display: flex;
			align-items: center;
			gap: 0.8rem;
			// background-color: red;
			cursor: pointer;

			figure {
				display: grid;
				height: fit-content;
				// width: 3rem;
				// height: 3rem;
				background-color: white;
				place-items: center;
				border-radius: 50%;

				svg {
					width: 100%;
					height: 100%;
				}
			}

			p {
				font-weight: 700;
				font-size: 1.4rem;
				line-height: 140%;
				color: #755ae2;
			}
		}
	}
}
