.link-card_container_wrap {
  background-color: #fff;
  margin-bottom: 2.4rem;
}
.link-card_container_wrap .the_link_item_box {
  display: flex;
  gap: 2rem;
}
.link-card_container_wrap .the_link_item_box .qr_code_holder {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  position: relative;
}
.link-card_container_wrap .the_link_item_box .qr_code_holder .img-box {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.link-card_container_wrap .the_link_item_box .qr_code_holder .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.link-card_container_wrap .link_card_details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}
.link-card_container_wrap .link_card_details .general_link_title {
  color: #000000;
  font-size: 2rem;
  font-weight: 500;
}
.link-card_container_wrap .link_card_details .general_link_subtitle {
  font-weight: 400;
  font-size: 1.4;
  color: #676767;
}
.link-card_container_wrap .general_bottom_content_wrap_holder {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  align-items: flex-start;
  gap: 3rem;
  row-gap: 2rem;
  border-width: 0.15rem;
  padding: 2rem 0rem;
}
.link-card_container_wrap .general_bottom_content_wrap_holder .label_value_box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.link-card_container_wrap .general_bottom_content_wrap_holder .label_value_box .name_label {
  color: rgb(139, 139, 139);
  font-size: 1.2rem;
}
.link-card_container_wrap .general_bottom_content_wrap_holder .label_value_box .value {
  font-size: 1.35rem;
  font-weight: 500;
}
.link-card_container_wrap .general_bottom_content_wrap_holder .label_value_box .value .tool-tip-wrap {
  font-weight: unset;
}
.link-card_container_wrap .link_container_and_disable_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
}
.link-card_container_wrap .link_container_and_disable_box .link_and_message_bod {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 1rem;
}
.link-card_container_wrap .link_container_and_disable_box .disable_and_icon_box {
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 0.1rem solid #ffa554;
  border: 0.1rem solid #FFA554;
  cursor: pointer;
  padding: 0.7rem;
  border-radius: 1.5rem;
  border-radius: 1rem;
}/*# sourceMappingURL=linkDetailsInfo.css.map */