.camera-assign-box-wrap {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.text {
		font-size: 1.25rem;
		color: #676767;

		span {
			color: #014345;
			font-weight: 600;
		}
	}

  .capture-note-box{
    display: flex;
    gap: .5rem;
    border-radius: 1.2rem;
    padding: 1rem ;
    background-color: rgba(255, 246, 237, 1);
    margin: -2rem 0rem 2rem 0rem;

    .img-note{
      .img-box{
        width: 2.5rem;
        height: 2.5rem;

        .img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .note{
      font-size: 1.25rem;
      font-weight: 500;
      
      span{
        color: #014345;
        font-weight: 700;
      }
    }
  }

	.camera-wrap-box {
		height: 17rem;
		background-color: transparent;
		width: 100%;
		border-radius: 1rem;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 4rem;
	}

	.two-btn-box {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1.5rem;
		width: 100%;
		margin-top: auto;
    // transform: translateY(4rem);
    // position: absolute;
    // top: 100%;
    // left: 0;

		& > * {
			width: 100%;
		}

		.cancel-btn {
			background-color: #f7f7f7;
			color: #020202;
			font-weight: 700;
			text-transform: capitalize;
		}
	}
}
