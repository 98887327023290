.settlement_modal_general_wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.settlement_modal_general_wrapper .settlement_details_modal_header {
  font-size: 2rem;
  font-weight: 400;
}
.settlement_modal_general_wrapper .settlement_details_modal_contents_box {
  border: 0.1rem solid #e5e6e6;
  border-radius: 2rem;
}
.settlement_modal_general_wrapper .settlement_details_modal_contents_box .settlement_details_modal_contents_content_holder {
  display: flex;
  border-bottom: 0.1rem solid #e5e6e6;
  padding: 3rem;
  justify-content: space-between;
  align-items: flex-start;
}
.settlement_modal_general_wrapper .settlement_details_modal_contents_box .settlement_details_modal_contents_content_holder .settlement_details_modal_contents_content_head {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
}
.settlement_modal_general_wrapper .settlement_details_modal_contents_box .settlement_details_modal_contents_content_holder .settlement_details_modal_contents_content_details {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
  align-self: flex-start;
}/*# sourceMappingURL=settlementModal.css.map */