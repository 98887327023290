.no-content-reusuable-wrap-box {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 10;
}

.no-content-reusuable-wrap-box .wrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.no-content-reusuable-wrap-box .wrap .img-box {
  width: 6.5rem;
  height: 6.5rem;
  display: grid;
  place-items: center;
  margin-bottom: 0rem;
}

.no-content-reusuable-wrap-box .wrap .img-box .img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.no-content-reusuable-wrap-box .wrap .title {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}

.no-content-reusuable-wrap-box .wrap .text {
  font-size: 1.35rem;
  text-align: center;
}

.no-content-reusuable-wrap-box .wrap .no-btn {
  margin-top: 2rem;
}


.spinner-wrap .img-box {
  width: 22rem;
  height: 22rem;
}
@media only screen and (max-width: 48em) {
  .spinner-wrap .img-box {
    width: 15rem;
    height: 15rem;
  }
}
.spinner-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=NoContentBox.css.map */