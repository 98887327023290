.forgot-password {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.forgot-password .forgot-password__logo {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.forgot-password .forgot-password__logo h5 {
  font-weight: 900;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--primary-blue);
}
.forgot-password .forgot-password__body-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10.3rem;
}
.forgot-password .forgot-password__body-wrap .body-wrap__back-to-login {
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.forgot-password .forgot-password__body-wrap .body-wrap__back-to-login:hover figure {
  transform: translateX(-3px);
}
.forgot-password .forgot-password__body-wrap .body-wrap__back-to-login figure {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: transform 300ms ease-in-out;
}
.forgot-password .forgot-password__body-wrap .body-wrap__back-to-login p {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #755ae2;
}
.forgot-password .forgot-password__body-wrap .body-wrap__main-content {
  background: #ffffff;
  width: 100%;
  border: 1px solid #e9f5ff;
  border-radius: 1.6rem;
  flex: none;
  padding: 4.8rem 2.4rem;
  margin-top: 3.7rem;
}
.forgot-password .forgot-password__body-wrap .body-wrap__main-content h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #000000;
}
.forgot-password .forgot-password__body-wrap .body-wrap__main-content span {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  color: #646464;
  display: flex;
  margin-top: 0.6rem;
}
.forgot-password .forgot-password__body-wrap .body-wrap__main-content .main-content__form {
  display: flex;
  flex-direction: column;
  margin-top: 3.6rem;
  gap: 7.6rem;
}
.forgot-password .new-password__body-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10.3rem;
}
.forgot-password .new-password__body-wrap .body-wrap__main-content {
  background: #ffffff;
  width: 100%;
  border: 1px solid #e9f5ff;
  border-radius: 1.6rem;
  flex: none;
  padding: 4.8rem 2.4rem;
  margin-top: 3.7rem;
}
.forgot-password .new-password__body-wrap .body-wrap__main-content h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
}
.forgot-password .new-password__body-wrap .body-wrap__main-content span {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  display: flex;
  margin-top: 0.6rem;
}
.forgot-password .new-password__body-wrap .body-wrap__main-content .main-content__form {
  display: flex;
  flex-direction: column;
  margin-top: 3.6rem;
  gap: 2.8rem;
}
.forgot-password .new-password__body-wrap .body-wrap__main-content .main-content__form .form__c2a {
  margin-top: 11.9rem;
}/*# sourceMappingURL=index.css.map */