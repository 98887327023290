.user_terminal_details_general_container_holder_wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem;
	position: relative;
	padding: 2rem 0rem;

	.user_terminal_details_general_container_holder {
		padding: 2rem;

		background-color: rgb(249, 249, 249);
		border-radius: 0.8rem;

		background-image: url('../../../../../../../../assets/halfbankboxs.png');
		background-position: right;
		background-repeat: no-repeat;
		background-size: contain;
		display: flex;
		flex-direction: column;
		gap: 2em;

		.header_content_holder_wrap {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			.terminal_hearder_name {
				font-weight: 700;
				font-size: 1.4rem;
				line-height: 2rem;
			}
			.terminal_serial_number {
				font-weight: 400;
				font-size: 1.2rem;
				line-height: 1.6rem;
				//  color: #646464;
			}
		}

		.modal_content_holder_wrapper {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			// position: absolute;
			background-color: #ffffff;
			// top: 8rem;
			// width: 90%;
			padding: 1rem;
			border-radius: 1rem;

			.modal_header_content {
				display: flex;
				justify-content: space-between;
				.modal_header_content_users_assignee {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}

				.modal_header_content_assignee {
					font-weight: 500;
					// color: #646464;
					font-size: 1rem;
				}
			}
			.modal_bottom_content {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				.modal_terminal_volumn {
					// color: #8b8b8b;
					font-weight: 400;
					font-size: 1rem;
				}
				.modal_terminal_volumn_content {
					// color: #333333;
					font-weight: 600;
					font-size: 1.4rem;
					line-height: 2rem;
				}
			}
		}
	}
}
