.checkmark__wrapper {
	width: 2.5rem;
	height: 2.5rem;
	display: grid;
	place-items: center;
	cursor: pointer;

	.copy-checkmark {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		background-color: #e8fff6;
		display: grid;
		place-items: center;

		svg {
			object-fit: contain;
			stroke: white !important;
			width: 75%;
			height: 75%;
		}
	}
}
