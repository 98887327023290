.fee-detail-dashboard-content-index-wrap {
	flex: 1;
	display: flex;
	flex-direction: column;

	.pagination-wrap {
		align-self: flex-end;
		margin-bottom: 1rem;
	}
}
