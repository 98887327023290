.business-card {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 2.3rem;

	.business-card__top {
		display: flex;
		gap: 2rem;
		// border-bottom: 0.1rem solid rgba(238, 238, 238, 0.05);
		// background-color: red;
		padding-bottom: 2rem;
		border-width: 0.15rem;

		.business-card__logo {
			display: flex;
			flex-direction: column;
			flex: 0 0 25%;
			align-self: stretch;
			position: relative;
			background-color: rgba(249, 249, 249, 1);
			border-radius: 1.6rem;

			.img-box {
				width: 100%;
				height: 100%;
				border-radius: 1.2rem;
				overflow: hidden;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: grid;
				place-items: center;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.business-card__details-balance-box {
			// background-color: blue;
			display: flex;
			flex-direction: column;
			// background-color: red;
			max-height: 35rem;
			overflow-y: scroll;
			width: 100%;

			/* width */
			&::-webkit-scrollbar {
				width: 0.5rem;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: none !important;
				background-color: transparent !important;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #020202;
				border-radius: 30rem;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #020202;
			}

			.business-card__details {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
				align-items: flex-start;
				gap: 3rem;
				row-gap: 3.5rem;
				flex: 1;
				border-width: 0.15rem;
				padding: 2rem 0rem;
				// background-color: blue;
				overflow-y: scroll;
				max-height: 24rem;
				cursor: pointer;

				.label-value-box {
					display: flex;
					flex-direction: column;
					gap: 0.4rem;

					.label {
						color: rgba(139, 139, 139, 1);
						font-size: 1.2rem;
					}

					.value {
						font-size: 1.35rem;
						font-weight: 500;

						.tool-tip-wrap {
							font-weight: unset;
						}
					}
				}
			}

			.business-card__balances {
				margin-top: 2rem;
				display: flex;
				flex-wrap: wrap;
				gap: 2rem;

				.label-value-box {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					padding: 1.2rem 1.5rem;
					border-radius: 1.2rem;
					border-width: 0.15rem;
					// min-width: ;
					min-width: 25rem;

					.label {
						color: rgba(139, 139, 139, 1);
						font-size: 1.45rem;
						display: flex;
						flex-direction: row;
						gap: 0.6rem;
						align-items: center;

						.img-box {
							width: 2rem;
							height: 2rem;

							.img,
							svg {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
					}

					.value {
						font-size: 2.3rem;
						font-weight: 500;

						.tool-tip-wrap {
							font-weight: unset;
						}
					}
				}
			}
		}

		.verified-badge {
			background-color: #f0fff4;
			color: #2ecc71;
			padding: 5px 10rem;
			margin-top: 1rem;
			border-radius: 1.5rem;
			font-size: 1.2rem;
		}

		// .business-card__details div {
		// 	font-size: 1.4rem;
		// }
	}

	/* Details Section */

	/* Balance Section */

	.wallet-balance,
	.lien-balance {
		border: 1px solid rgba(238, 238, 238, 1);
		padding: 1rem;
		border-radius: 8px;
		// text-align: center;
	}

	.wallet_con {
		align-items: center;
		gap: 1rem;
		display: flex;
	}

	.lien-balance {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-top: 2rem;
		width: 25%;
	}

	.wallet-balance h3,
	.lien-balance h3 {
		margin: 0.5rem 0 0;
		font-size: 1.8rem;
		font-weight: 500;
		color: #333;
	}

	/* Action Buttons */
	.business-card__actions {
		display: flex;
		// justify-content: space-around;
		margin-top: 1rem;
		gap: 2rem;

		.action-btn {
			padding: 1.2rem 1.4rem;
			background-color: transparent;
			// border: 1px solid rgba(172, 172, 172, 1);
			display: flex;
			align-items: center;
			gap: 1rem;
			border-radius: 0.8rem;
			cursor: pointer;
			font-size: 1.3rem;
			transition: all 0.2s ease;
			border-width: 0.15rem;

			.icon {
				width: 2rem;
				height: 2rem;

				svg,
				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}
}

.layout_main {
	box-shadow: none !important;
	padding: 0rem !important;

	.children-wrap-box{
		min-height: 55rem !important;
	}
}

/* Top Section */

.single-merchant-unique-bank-name-box {
	display: flex;
	align-items: center;
	gap: 1rem;
	// background-color: red;

	.name {
		font-weight: 600;
	}

	.name-number-box {
		.small-text {
			text-wrap: nowrap;
		}
	}

	.grey-white-color {
		font-size: 1.2rem;
	}

	.icon-wrap {
		border-width: 0.15rem;
	}

	.img-box {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;

		.img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.flex_bank_code {
	display: flex;
	align-items: center;
	gap: 1rem;

	.icon-wrap {
		height: 4rem;
		width: 4rem;
		border-radius: 50%;
		border-width: 0.15rem;
	}
}
