.bills_modal_contents_content_holder_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.bills_modal_contents_content_holder_wrap .bills_details_modal_header {
  font-size: 2rem;
  font-weight: 400;
}
.bills_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder_wrap {
  border: 0.1rem solid #e5e6e6;
  display: flex;
  flex-direction: column;
}
.bills_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder {
  display: flex;
  border-bottom: 0.1rem solid #e5e6e6;
  padding: 1.5rem;
  gap: 4rem;
  align-items: flex-start;
}
.bills_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder .bills_details_modal_contents_content_head {
  flex: 0 0 30%;
}
.bills_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder_wrap .bills_details_modal_contents_content_holder .bills_details_modal_contents_content_details {
  flex: 1;
}/*# sourceMappingURL=billsModalIndex.css.map */