.modal-container {
  display: flex;
  flex-direction: column;
}

.modal-header {
  margin-bottom: 1.5rem;
}

.modal-title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.2rem;
  color: rgb(27, 27, 27);
}

.modal-body {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: rgb(103, 103, 103);
  margin-bottom: 5rem;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.btn-cancel {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #000;
  cursor: pointer;
}

.btn-cancel:hover {
  background: #f5f5f5;
}

.btn-confirm {
  flex: 1;
  background: #005046;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #fff;
  cursor: pointer;
}

.btn-confirm:hover {
  background: #003d34;
}/*# sourceMappingURL=Settoapprove.css.map */