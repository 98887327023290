.dashboard-sidemenu-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  padding-left: 2rem;
}
.dashboard-sidemenu-wrap .logo-text-box {
  display: flex;
  align-items: center;
}
.dashboard-sidemenu-wrap .logo-text-box .text {
  font-weight: 600;
  font-size: 1.35rem;
}
.dashboard-sidemenu-wrap .logo-text-box .logo-box {
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  height: -moz-min-content;
  height: min-content;
}
.dashboard-sidemenu-wrap .logo-text-box .logo-box .img-box {
  width: 10rem;
  height: -moz-min-content;
  height: min-content;
}
.dashboard-sidemenu-wrap .logo-text-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-sidemenu-wrap .name-email-drop-box {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.7rem;
  border: 0.1rem solid #0b8376;
  border-radius: 0.8rem;
  width: calc(100% - 2rem);
  align-items: center;
}
.dashboard-sidemenu-wrap .name-email-drop-box .icon-box {
  background: rgba(255, 255, 255, 0.12);
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-sidemenu-wrap .name-email-drop-box .icon-box .icon {
  color: #9be5c9;
  width: 70%;
  width: 70%;
}
.dashboard-sidemenu-wrap .name-email-drop-box .name-email-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.dashboard-sidemenu-wrap .name-email-drop-box .name-email-box .name {
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 700;
}
.dashboard-sidemenu-wrap .name-email-drop-box .name-email-box .email {
  color: #cccccc;
  font-size: 1.2rem;
}
.dashboard-sidemenu-wrap .nav-list-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  gap: 1.5rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dashboard-sidemenu-wrap .nav-list-box::-webkit-scrollbar {
  display: none;
}
.dashboard-sidemenu-wrap .nav-list-box .toggle-nav-box .get-started {
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  padding-right: 2rem;
  color: #ffffff;
}
.dashboard-sidemenu-wrap .nav-list-box .toggle-nav-box .get-started .title {
  margin-right: auto;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box {
  display: flex;
  flex-direction: column;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0rem 1.5rem 0rem;
  cursor: pointer;
  position: relative;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link:hover .text {
  color: #ffffff;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  width: 0.5rem;
  background-color: #ffffff;
  transform: translateY(-50%);
  visibility: hidden;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(269.91deg, rgba(255, 255, 255, 0.1) 22.13%, rgba(255, 255, 255, 0) 96.37%);
  transform: translateY(-50%);
  visibility: hidden;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link > * {
  position: relative;
  z-index: 3;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link .text {
  color: #cccccc;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link .img-wrap .children-spacing {
  padding-left: 3.5rem;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link .img-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link .img-wrap .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link .img-wrap .bankbox-icon .img {
  width: 70%;
  height: 70%;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link-active {
  cursor: default;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link-active .text {
  color: #ffffff;
  font-weight: 700;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link-active::after, .dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link-active::before {
  visibility: visible;
}
.dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link-active .img-box {
  background-color: #020202 !important;
}
.dashboard-sidemenu-wrap .nav-list-box .parent-children-box .nav-link-drop {
  padding-right: 2rem;
}
.dashboard-sidemenu-wrap .nav-list-box .parent-children-box .nav-link-drop .text {
  margin-right: auto;
}
.dashboard-sidemenu-wrap .nav-list-box .parent-children-box .nav-link-drop .icon {
  color: #cccccc;
}
.dashboard-sidemenu-wrap .nav-list-box .parent-children-box .children-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.dashboard-sidemenu-wrap .nav-list-box .parent-children-box .children-box .img-box {
  margin-left: 3rem;
}
.dashboard-sidemenu-wrap .nav-list-box .parent-children-box .children-box-show {
  margin-bottom: 0rem;
  max-height: 30rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
}
.dashboard-sidemenu-wrap .toggle-hide-show {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
  margin-bottom: 1.5rem;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0rem;
  cursor: pointer;
  position: relative;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link:hover .text {
  color: #ffffff;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  width: 0.5rem;
  background-color: #ffffff;
  transform: translateY(-50%);
  visibility: hidden;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(269.91deg, rgba(255, 255, 255, 0.1) 22.13%, rgba(255, 255, 255, 0) 96.37%);
  transform: translateY(-50%);
  visibility: hidden;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link > * {
  position: relative;
  z-index: 3;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link .text {
  color: #cccccc;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link .img-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link-active {
  cursor: default;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link-active .text {
  color: #ffffff;
  font-weight: 700;
}
.dashboard-sidemenu-wrap .toggle-hide-show .nav-link-active::after, .dashboard-sidemenu-wrap .toggle-hide-show .nav-link-active::before {
  visibility: visible;
}
.dashboard-sidemenu-wrap .toggle-show {
  margin-bottom: 0rem;
  max-height: 15rem;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
}
.dashboard-sidemenu-wrap .chat-support-box {
  margin-top: auto;
  width: calc(100% - 2rem);
  min-height: 14rem;
  border-radius: 1.2rem;
  background: rgba(255, 255, 255, 0.08);
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  background-image: url("../../../../assets/help-chat-mascot.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.dashboard-sidemenu-wrap .chat-support-box > * {
  width: 100%;
}/*# sourceMappingURL=index.css.map */