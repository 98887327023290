.nin-card {
	// border: 1px solid #ddd;
	border-radius: 8px;
	padding: 1.6rem;
	margin: 3rem 0;
	display: flex;
	flex-direction: column;
	// box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-size: 1.8rem;
			font-weight: 500;
		}

		.revoke-button {
			background-color: rgba(255, 239, 238, 1);
			color: rgba(255, 15, 0, 1);
			border: none;
			padding: 1.5rem 1.6rem;
			border-radius: 1rem;
			cursor: pointer;
		}
	}

	.card-body {
		display: flex;
		margin-top: 1.6rem;
		gap: 3rem;

		.image-section {
			display: flex;
			flex-direction: column;
			flex: 0 0 25%;
			align-self: stretch;
			position: relative;

			.img-box {
				width: 100%;
				height: 100%;
				border-radius: 1.2rem;
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		._contents_box {
			border: 0.1rem solid #e5e6e6;
			border-radius: 2rem;
			width: 100%;
		}

		.contents_content_holder {
			display: flex;
			border-bottom: 0.1rem solid #e5e6e6;
			padding: 3rem;
			justify-content: space-between;
			align-items: flex-start;
		}

		.contents_content_holder-btm {
			padding: 3rem;
			display: flex;
			// border-bottom: 0.1rem solid #e5e6e6;
			// padding: 3rem;
			justify-content: space-between;
			align-items: flex-start;
		}

		._contents_content_head {
			font-size: 1.4rem;
			font-weight: 400;
			color: #000000;
		}

		._contents_content_details {
			font-size: 1.4rem;
			font-weight: 400;
			color: rgb(57, 54, 54);
			align-self: flex-start;
		}
	}
}
