.bankbox-user-view {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: var(--themelightmode, #fff);
  padding: 0 1.7rem;
}
.bankbox-user-view__left {
  display: flex;
  padding-top: 2.7rem;
  flex-direction: column;
  width: 70%;
  border-right: 1px solid var(--raven-black-100, #eee);
  overflow-y: scroll;
  padding-bottom: 3rem;
}
.bankbox-user-view__left--title {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
}
.bankbox-user-view__left--title .left {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-right: auto;
}
.bankbox-user-view__left--title h6 {
  color: var(--themedarkmode, #000);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.bankbox-user-view__left--title p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.bankbox-user-view__left--date-location {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.2rem 0;
  border-top: 1.5px solid var(--raven-black-100, #eee);
  border-bottom: 1.5px solid var(--raven-black-100, #eee);
  gap: 3.2rem;
}
.bankbox-user-view__left--date-location .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
}
.bankbox-user-view__left--date-location .item figure {
  display: grid;
  width: 4.5rem;
  height: 4.5rem;
  place-items: center;
}
.bankbox-user-view__left--date-location .item figure svg {
  width: 100%;
  height: 100%;
}
.bankbox-user-view__left--date-location .item p {
  color: var(--themedarkmode, #000);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.bankbox-user-view__left--user-details {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 100%;
  padding-right: 2.3rem;
  margin-top: 2.5rem;
  padding-bottom: 2rem;
}
.bankbox-user-view__left--user-details .details-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.4rem;
}
.bankbox-user-view__left--user-details .details-row .details {
  display: flex;
  padding: 2.4rem 1.2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid var(--raven-black-100, #eee);
}
.bankbox-user-view__left--user-details .details-row .details .bank-name-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bankbox-user-view__left--user-details .details-row .details .bank-name-box .name {
  font-weight: 600;
}
.bankbox-user-view__left--user-details .details-row .details .bank-name-box .grey-white-color {
  font-size: 1.2rem;
}
.bankbox-user-view__left--user-details .details-row .details .bank-name-box .icon-wrap {
  border-width: 0.15rem;
}
.bankbox-user-view__left--user-details .details-row .details .bank-name-box .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.bankbox-user-view__left--user-details .details-row .details .bank-name-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bankbox-user-view__left--user-details .details-row .details span {
  color: var(--base-black-base-bg-dark, #676767);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
}
.bankbox-user-view__left--user-details .details-row .details p {
  color: var(--base-black-base-background-dark, #020202);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.bankbox-user-view__left .fee-details-info-box {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 2rem;
  margin-top: 2rem;
}
.bankbox-user-view__left .fee-details-info-box .title-change-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-width: 0.15rem;
}
.bankbox-user-view__left .fee-details-info-box .title-change-box .title,
.bankbox-user-view__left .fee-details-info-box .title-change-box .change {
  font-weight: 600;
  font-size: 1.55rem;
}
.bankbox-user-view__left .fee-details-info-box .title-change-box .change {
  color: #014345;
  cursor: pointer;
}
.bankbox-user-view__left .fee-details-info-box .detail-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  grid-gap: 2rem;
  margin-top: 1.5rem;
}
.bankbox-user-view__left .fee-details-info-box .detail-box > *:not(:last-child) {
  padding-right: 2rem;
}
.bankbox-user-view__left .fee-details-info-box .detail-box .label-value-box {
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  gap: 0.5rem;
  border-width: 0.15rem;
}
.bankbox-user-view__left .fee-details-info-box .detail-box .label-value-box .label {
  font-size: 1.3rem;
}
.bankbox-user-view__left .fee-details-info-box .detail-box .label-value-box .value {
  font-size: 1.55rem;
  font-weight: 600;
}
.bankbox-user-view__right {
  display: flex;
  width: 30%;
  height: 100%;
  flex-direction: column;
}
.bankbox-user-view__right--title {
  padding: 1.6rem;
  border-radius: var(--Count, 0px);
  border-bottom: 1px solid var(--raven-black-100, #eee);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.bankbox-user-view__right--title figure {
  display: grid;
  width: 4.5rem;
  height: 4.5rem;
  place-items: center;
}
.bankbox-user-view__right--title figure svg {
  width: 100%;
  height: 100%;
}
.bankbox-user-view__right--title h6 {
  color: var(--themedarkmode, #000);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}
.bankbox-user-view__right--title .select-item-wrap {
  padding: 1.5rem 2.5rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.bankbox-user-view__right--title .select-item-wrap::after {
  content: "";
  position: absolute;
  top: 130%;
  left: 0%;
  height: 0.5rem;
  width: 100%;
  background-color: #014345;
  border-radius: 5rem;
  visibility: hidden;
}
.bankbox-user-view__right--title .select-item-wrap-active {
  cursor: unset;
}
.bankbox-user-view__right--title .select-item-wrap-active span {
  color: #014345;
  font-weight: 600;
}
.bankbox-user-view__right--title .select-item-wrap-active::after {
  visibility: visible;
}
.bankbox-user-view__right--devices-contain {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100vh - 25rem);
  overflow-y: auto;
  padding: 2.4rem 1.6rem;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card {
  padding: 1.5rem;
  border-radius: 0.8rem;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card {
  padding: 2rem;
  border-radius: 0.8rem;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .top-box {
  display: flex;
  justify-content: space-between;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .top-box .quantity-ref-box .quantity {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .top-box .quantity-ref-box .ref {
  font-weight: 600;
  font-size: 1.5rem;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .bottom-box {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .bottom-box .user-img-box {
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .bottom-box .user-img-box .img-box {
  width: 60%;
  height: 60%;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .bottom-box .user-img-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.bankbox-user-view__right--devices-contain .request-box-wrap-card .box-wrap-card .bottom-box .email {
  font-size: 1.25rem;
}
.bankbox-user-view__right--devices-contain .empty_all_bb {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bankbox-user-view__right--devices-contain .empty_all_bb h6 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  color: #014345;
}
.bankbox-user-view__right--devices-contain .empty_all_bb p {
  color: var(--RAVEN-GREY, #676767);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.bankbox-user-view__right--devices-contain .device {
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  background: var(--raven-black-50, #f7f7f7);
  padding: 2rem 1.2rem;
  background-image: url("../../../../../assets/images/bank-box.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  position: relative;
}
.bankbox-user-view__right--devices-contain .device .badge-user-box {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.bankbox-user-view__right--devices-contain .device__business {
  display: flex;
  flex-direction: column;
}
.bankbox-user-view__right--devices-contain .device__business h6 {
  color: var(--raven-black-800, #333);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
}
.bankbox-user-view__right--devices-contain .device__business p {
  color: var(--raven-black-600, #646464);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.bankbox-user-view__right--devices-contain .device__owner {
  display: inline-flex;
  padding: 0.8rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4rem;
  border-radius: 10px;
  margin-top: 1.8rem;
  background: var(--themelightmode, #fff);
}
.bankbox-user-view__right--devices-contain .device__owner--email {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.bankbox-user-view__right--devices-contain .device__owner--email figure {
  display: grid;
  width: 2.5rem;
  height: 2.5rem;
  place-items: center;
}
.bankbox-user-view__right--devices-contain .device__owner--email figure svg {
  width: 100%;
  height: 100%;
}
.bankbox-user-view__right--devices-contain .device__owner--email p {
  color: var(--raven-black-600, #646464);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 14px */
}
.bankbox-user-view__right--devices-contain .device__owner--trx-volume {
  display: flex;
  flex-direction: column;
}
.bankbox-user-view__right--devices-contain .device__owner--trx-volume p {
  color: var(--raven-black-500, #8b8b8b);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
}
.bankbox-user-view__right--devices-contain .device__owner--trx-volume h6 {
  color: var(--raven-black-800, #333);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
}

.bankbox-assign-btn {
  color: var(--primary-darkgreen-100, #014345);
  font-style: normal;
  font-weight: 600;
  background: none;
  border-radius: 10px;
  border: 1px solid var(--raven-grey-shade-50, #ccc);
}

.assign-bb-modal {
  display: flex;
  flex-direction: column;
}
.assign-bb-modal .css-1nmdiq5-menu {
  width: 98%;
  left: 50%;
  transform: translateX(-50%);
}
.assign-bb-modal .amount-show-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.assign-bb-modal .amount-show-box .text-amount- {
  font-size: 1.25rem;
}
.assign-bb-modal .amount-show-box .text-amount- span {
  color: unset !important;
}
.assign-bb-modal .amount-show-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.assign-bb-modal .amount-show-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.assign-bb-modal .settle-ment-account-wrap {
  display: flex;
  flex-direction: column;
}
.assign-bb-modal .settle-ment-account-wrap .label {
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  color: #020202;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box {
  padding: 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box .name {
  font-size: 1.35rem;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box .acc-num {
  font-size: 2rem;
  font-weight: 700;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border-width: 0.15rem;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap .img-box {
  width: 100%;
  height: 100%;
}
.assign-bb-modal .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.assign-bb-modal .two-input-field-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .assign-bb-modal .two-input-field-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
.assign-bb-modal .camera-icon-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.assign-bb-modal .camera-icon-box .camera-icon {
  display: flex;
  align-items: center;
}
.assign-bb-modal .camera-icon-box .camera-icon .img-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.assign-bb-modal .camera-icon-box .camera-icon .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.assign-bb-modal .auto-generate-box-wrap {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.assign-bb-modal .auto-generate-box-wrap .text-white-light {
  color: #ffffff !important;
}
.assign-bb-modal .auto-generate-box-wrap .info-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.assign-bb-modal .auto-generate-box-wrap .info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.assign-bb-modal .auto-generate-box-wrap .togle-info-box {
  transform: scale(0.8);
}
.assign-bb-modal h6 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}
.assign-bb-modal p {
  color: #676767;
}
.assign-bb-modal__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.8rem;
}
.assign-bb-modal__form-body {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-height: 45rem;
  overflow-y: scroll;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__title {
  display: flex;
  flex-direction: column;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__title h5 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__title p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__export-as {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__export-as--selector-wrap {
  display: flex;
  gap: 1.6rem;
  margin-bottom: 2.8rem;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__export-as--selector-wrap .selector-wrap__item {
  display: flex;
  height: 6rem;
  padding: 0.8rem 1.2rem;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 1.2rem;
  border: 1px solid var(--raven-black-100, #eee);
  background: var(--base-white, #fff);
  margin-top: 1.2rem;
  cursor: pointer;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__export-as--selector-wrap .selector-wrap__item.selected {
  border: 2px solid var(--primary-darkgreen-100, #014345);
  background: var(--raven-black-50, #f7f7f7);
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector {
  display: flex;
  flex-direction: column;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding: 2rem 0;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap .days-selector__item {
  display: flex;
  padding: 0.8rem 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 72px;
  cursor: pointer;
  background: var(--raven-black-50, #f7f7f7);
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected {
  border-radius: 72px;
  border: 1.4px solid var(--primary-darkgreen-100, #014345);
  background: var(--raven-black-100, #eee);
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected figure svg path {
  stroke: #014345;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected figure {
  border-radius: 50rem;
  width: -moz-min-content;
  width: min-content;
  height: -moz-min-content;
  height: min-content;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected p {
  color: var(--primary-darkgreen-100, #014345);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 16.8px */
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__days-selector .days-selector__item-wrap .days-selector__item p {
  color: var(--raven-black-500, #8b8b8b);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__custom-date {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 4.9rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-in-out;
}
.assign-bb-modal__form-body .auto-assign-content-box .export-modal__custom-date.show {
  max-height: 400rem;
  transition: max-height 2s ease-in-out;
}

.serial-number-input-form-group {
  position: relative;
  width: 100%;
}
.serial-number-input-form-group .text-white-light {
  color: #ffffff !important;
}
.serial-number-input-form-group .upload-toggle-box {
  position: absolute;
  top: 37%;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  z-index: 10;
  border-width: 0.15rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.serial-number-input-form-group .upload-toggle-box:hover .img-box {
  transform: scale(1.05);
}
.serial-number-input-form-group .upload-toggle-box .img-box {
  width: 50%;
  height: 50%;
  transition: transform 0.3s ease-in-out;
}
.serial-number-input-form-group .upload-toggle-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.test {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-gap: 1.5rem;
}/*# sourceMappingURL=index.css.map */