.alert-receiver-modal-wrap-index {
  display: flex;
  flex-direction: column;
  min-height: 25rem;
  max-height: 45rem;
}
.alert-receiver-modal-wrap-index .title-text-box {
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
.alert-receiver-modal-wrap-index .title-text-box .title {
  font-weight: 600;
  font-size: 2.3rem;
}
.alert-receiver-modal-wrap-index .title-text-box .text {
  color: rgb(103, 103, 103);
}
.alert-receiver-modal-wrap-index .receiver-list-box {
  margin-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .info-span-box {
  background-color: #fff6ed;
  border: 0.05rem solid #ea872d;
  padding: 1.5rem;
  border-radius: 1.2rem;
  margin: 2rem 0rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .info-span-box .info {
  font-size: 1.45rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .info-span-box .info span {
  font-weight: 600;
  color: #ea872d;
}
.alert-receiver-modal-wrap-index .receiver-list-box::-webkit-scrollbar {
  display: none;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box {
  display: flex;
  flex-direction: column;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box .name {
  font-weight: 600;
  font-size: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box .number {
  font-size: 1.25rem;
}/*# sourceMappingURL=AlertReceivers.css.map */