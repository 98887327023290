.dashboard-layout-headernav-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1.4rem;
  align-items: center;
}
.dashboard-layout-headernav-wrap .back-text-icon-wrap-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.dashboard-layout-headernav-wrap .back-text-icon-wrap-box:hover .img-wrap {
  transform: translateX(-0.12rem);
}
.dashboard-layout-headernav-wrap .back-text-icon-wrap-box .text {
  color: rgb(1, 67, 69);
  font-size: 1.55rem;
}
.dashboard-layout-headernav-wrap .back-text-icon-wrap-box .img-wrap {
  transition: all 0.3s ease-in-out;
}
.dashboard-layout-headernav-wrap .back-text-icon-wrap-box .img-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.dashboard-layout-headernav-wrap .back-text-icon-wrap-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-layout-headernav-wrap .mobile-hamburger {
  display: none;
}
.dashboard-layout-headernav-wrap .search-box {
  margin-right: auto;
}
.dashboard-layout-headernav-wrap .search-box form .form-group .input-group input:active {
  border: unset;
}
.dashboard-layout-headernav-wrap .profile-avatar-box .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.dashboard-layout-headernav-wrap .profile-avatar-box .img-box .img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-radius: 50%;
  height: 100%;
}
.dashboard-layout-headernav-wrap .profile-avatar-box .avatar-box {
  width: 4.1rem;
  height: 4.1rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-layout-headernav-wrap .profile-avatar-box .avatar-box span {
  display: inline-block;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.dashboard-layout-headernav-wrap .theme-switch-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.6rem 1rem;
  border-radius: 1.2rem;
}
.dashboard-layout-headernav-wrap .theme-switch-box .switch {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
}
.dashboard-layout-headernav-wrap .theme-switch-box .switch-active {
  cursor: default;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
}
.dashboard-layout-headernav-wrap .mode-box {
  display: flex;
  align-items: center;
  padding: 0.9rem 1.5rem;
  border-radius: 0.8rem;
  border: 0.125rem solid #e5e6e6;
  cursor: pointer;
  min-width: 17rem;
  gap: 0.8rem;
}
.dashboard-layout-headernav-wrap .mode-box .color-box {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  background-color: #ea872d;
}
.dashboard-layout-headernav-wrap .mode-box .text {
  margin-right: auto;
  font-weight: 600;
  font-size: 1.35rem;
}
.dashboard-layout-headernav-wrap .mode-box .icon-box {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-layout-headernav-wrap .mode-box .icon-box .icon {
  width: 60%;
  height: 60%;
}
.dashboard-layout-headernav-wrap .api-documentation-box {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  border: 0.125rem solid #e5e6e6;
  cursor: pointer;
}
.dashboard-layout-headernav-wrap .api-documentation-box:hover .img-box {
  transform: translateX(0.2rem) translateY(-0.2rem);
}
.dashboard-layout-headernav-wrap .api-documentation-box .text {
  font-weight: 600;
  font-size: 1.35rem;
  color: #755ae2;
}
.dashboard-layout-headernav-wrap .api-documentation-box .img-box {
  width: 1.2rem;
  height: 1.2rem;
  transition: all 0.3s ease-in-out;
}
.dashboard-layout-headernav-wrap .api-documentation-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.legacy_toast {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.6rem;
}
.legacy_toast button {
  border: none;
  background: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.237);
  padding: 0.6rem 2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4941176471);
  font-size: 1.2rem;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=index.css.map */