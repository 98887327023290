.team-settings-index-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.team-settings-index-wrap-box .top-select-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.team-settings-index-wrap-box .top-select-box .smartfilter {
  margin-bottom: 0rem;
}
.team-settings-index-wrap-box .top-select-box .btn {
  min-width: 15rem;
}
.team-settings-index-wrap-box .top-select-box .settings-pagination-box {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-width: 0.15rem;
  border-radius: 0.8rem;
  align-self: stretch;
}
.team-settings-index-wrap-box .top-select-box .settings-pagination-box > * {
  width: -moz-max-content;
  width: max-content;
}
.team-settings-index-wrap-box .table-setting-body {
  margin-top: 2rem;
}
.team-settings-index-wrap-box .table-setting-body .pending-row > *:first-child, .team-settings-index-wrap-box .table-setting-body .pending-row > *:nth-child(2), .team-settings-index-wrap-box .table-setting-body .pending-row > *:nth-child(3) {
  opacity: 0.4;
}/*# sourceMappingURL=TeamSettingsIndex.css.map */