.payment_links_component_container_wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  padding: 0rem 1rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content {
  padding: 1rem;
  padding-bottom: 2rem;
  border-radius: 1.2rem;
  position: relative;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner {
  display: flex;
  border-radius: 1.2rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .qrCode_holder {
  height: 12rem;
  flex: 0 0 30%;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .qrCode_holder .img-box {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .payment_links_component_container_wrap_content_box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .payment_links_component_container_wrap_content_box .payment_links_component_container_wrap_content_name_and_pin {
  display: flex;
  justify-content: space-between;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .payment_links_component_container_wrap_content_box .payment_links_component_container_wrap_content_name_and_pin .payment_link_name {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .payment_links_component_container_wrap_content_box .payment_links_component_container_wrap_content_name_and_pin .payment_link_subscription_name {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .payment_links_component_container_wrap_content_inner .payment_links_component_container_wrap_content_box .payment_links_component_container_wrap_content_link_and_messageicon {
  display: flex;
  background-color: #f7f7f7;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem;
  border-radius: 1rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .status_and_charge_box {
  display: flex;
  gap: 2.5rem;
  margin-top: 2rem;
  padding-top: 2rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .status_and_charge_box .payment_link_status_box {
  background-color: #E8FFF6;
  width: -moz-max-content;
  width: max-content;
  color: #1ACE37;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
}
.payment_links_component_container_wrap .payment_links_component_container_wrap_content .status_and_charge_box .payment_link_charge_box {
  background-color: #F7F8F7;
  width: -moz-max-content;
  width: max-content;
  color: #000000;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
}/*# sourceMappingURL=paymentLinksComponentIndex.css.map */