.alert-receiver-modal-wrap-index {
	display: flex;
	flex-direction: column;
	min-height: 25rem;
	max-height: 45rem;

	.title-text-box {
		margin-top: -1rem;
		display: flex;
		flex-direction: column;
		gap: 0.3rem;
		margin-bottom: 1rem;

		.title {
			font-weight: 600;
			font-size: 2.3rem;
		}

		.text {
			color: rgba(103, 103, 103, 1);
		}
	}

	.receiver-list-box {
		margin-top: 2rem;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		gap: 1.5rem;

		.info-span-box {
			background-color: #fff6ed;
			border: 0.05rem solid #ea872d;
			padding: 1.5rem;
			border-radius: 1.2rem;
			margin: 2rem 0rem;

			.info {
				font-size: 1.45rem;

				span {
					font-weight: 600;
					color: #ea872d;
				}
			}
		}

		&::-webkit-scrollbar {
			display: none;
		}

		.item-box {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.name-num-box {
				display: flex;
				flex-direction: column;

				.name {
					font-weight: 600;
					font-size: 1.5rem;
				}

				.number {
					font-size: 1.25rem;
				}
			}
		}
	}
}
