.liveness-modal-container.modal-parent-wrap .raven-modal-content-wrap {
  width: 85rem !important;
  height: 70% !important;
}

.liveliness-check-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.liveliness-check-modal .modal-content {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
}
.liveliness-check-modal .modal-content .image-container {
  margin-right: 2rem;
}
.liveliness-check-modal .modal-content .details-container h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
.liveliness-check-modal .modal-content .details-container table {
  width: 100%;
  margin-top: 1rem;
}
.liveliness-check-modal .modal-content .details-container td {
  padding: 1.5rem;
  border: 1px solid rgb(227, 227, 227);
}
.liveliness-check-modal .modal-content .details-container p {
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
}/*# sourceMappingURL=ComplianceLivelinessCheck.css.map */