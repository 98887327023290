.bills-layout-index-wrap-box {
	// width: 100%;
	// height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	height: calc(100vh - 8rem);
	overflow-y: scroll;
	.bills_layout {
		padding: 0 !important;
	}
	.children-route-wrapper-box {
		display: flex;
		flex-direction: column;
		flex: 1;
		border-radius: 1.2rem;
		box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
		overflow-y: scroll;

		gap: 1.5rem;

		.route-path-wrap-box {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 2rem;
			padding-bottom: 2rem;
			border-width: 0.15rem;
			min-height: 7rem;
			overflow-x: scroll;
			// background-color: red;

			.right-box-btn {
				margin-left: auto;
				// width: 7rem;
				// background-color: red;
				// height: 4rem;
			}

			.item-row {
				padding: 1.2rem 1.6rem;
				border-radius: 0.8rem;
				position: relative;
				cursor: pointer;

				&::after {
					content: '';
					position: absolute;
					top: 100%;
					left: 0%;
					width: 100%;
					height: 0.5rem;
					background-color: rgba(1, 67, 69, 1);
					border-radius: 2rem;
					transform: translateY(1.5rem);
					visibility: hidden;
				}

				.name {
					transition: all 0.3s ease-in;
				}

				&:hover {
					.name {
						font-weight: 600;
						color: rgba(1, 67, 69, 1);
					}
				}
			}

			.item-row-active {
				background-color: rgba(232, 255, 246, 1);
				cursor: default;

				&::after {
					visibility: visible;
				}

				.name {
					font-weight: 600;
					color: rgba(1, 67, 69, 1);
				}
			}
		}

		.children-wrap-box {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow-y: scroll;
			position: relative;
		}
	}
}

.reuseable-wrapper-route-path-wrap-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2rem;
	padding-bottom: 2rem;
	border-width: 0.15rem;
	min-height: 7rem;
	margin-bottom: 1.5rem;
	position: relative;
	z-index: 1000;
	// background-color: red;
	// overflow-x: scroll;
	// overflow-y: visible;

	.right-box-btn {
		margin-left: auto;
		// width: 7rem;
		// background-color: red;
		// height: 4rem;
	}

	.item-row {
		padding: 1.2rem 1.6rem;
		border-radius: 0.8rem;
		position: relative;
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0%;
			width: 100%;
			height: 0.5rem;
			background-color: rgba(1, 67, 69, 1);
			border-radius: 2rem;
			transform: translateY(1.5rem);
			visibility: hidden;
		}

		.name {
			transition: all 0.3s ease-in;
			text-wrap: nowrap;
		}

		.name-children {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			cursor: pointer;

			.img-box {
				width: 2rem;
				height: 2rem;
				margin-top: 0.4rem;

				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		&:hover {
			.name {
				font-weight: 600;
				color: rgba(1, 67, 69, 1);
			}
		}

		.drop-box-wrap {
			position: absolute;
			top: 150%;
			left: 30%;
			min-width: 17rem;
			max-height: 25rem;
			border-radius: 0.8rem;
			border-width: 0.12rem;
			display: flex;
			flex-direction: column;
			// gap: 1.5rem;
			overflow-y: scroll;

			// & > *:not(:last-child) {
			// 	padding-bottom: 1.5rem;
			// }

			& > *:last-child {
				border-bottom: unset;
			}

			.child-name {
				padding: 1.5rem;
				border-width: 0.12rem;
			}
		}
	}

	.item-row-active {
		background-color: rgba(232, 255, 246, 1);
		cursor: default;

		&::after {
			visibility: visible;
		}

		.name {
			font-weight: 600;
			color: rgba(1, 67, 69, 1);
		}
	}
}
