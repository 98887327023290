.input_filter_export_general_wrap {
	display: flex;
	gap: 1rem;
	// align-items: center;
	.pagination-wrap {
		width: 15rem;
		margin-top: 1.5rem;
		.top-bar__pagination {
			display: flex;
		}
	}
}
.fee-detail-dashboard-content-index-wrap {
	.table__main {
		.status_and_modal_holder {
			display: flex;
			padding-right: 2rem;
			align-items: center;
			justify-content: space-between;
		}
	}
}
.provider_image_and_name {
	display: flex;
	gap: 1rem;
	align-items: center;

	.img-box {
		border-radius: 50%;
		width: 3.5rem;
		height: 3.5rem;
		overflow: hidden;

		.img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.img-box-default {
		background-color: rgba(1, 67, 69, 1);
		display: flex;
		align-items: center;
		justify-content: center;

		.img {
			width: 60%;
			height: 60%;

			path {
				fill: transparent !important;
				stroke: #ffffff !important;
			}
		}
	}
}
