.business-card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2.3rem;
  /* Details Section */
  /* Balance Section */
  /* Action Buttons */
}
.business-card .business-card__top {
  display: flex;
  gap: 2rem;
  padding-bottom: 2rem;
  border-width: 0.15rem;
}
.business-card .business-card__top .business-card__logo {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  align-self: stretch;
  position: relative;
  background-color: rgb(249, 249, 249);
  border-radius: 1.6rem;
}
.business-card .business-card__top .business-card__logo .img-box {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}
.business-card .business-card__top .business-card__logo .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.business-card .business-card__top .business-card__details-balance-box {
  display: flex;
  flex-direction: column;
  max-height: 35rem;
  overflow-y: scroll;
  width: 100%;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.business-card .business-card__top .business-card__details-balance-box::-webkit-scrollbar {
  width: 0.5rem;
}
.business-card .business-card__top .business-card__details-balance-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.business-card .business-card__top .business-card__details-balance-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.business-card .business-card__top .business-card__details-balance-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__details {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  align-items: flex-start;
  gap: 3rem;
  row-gap: 3.5rem;
  flex: 1;
  border-width: 0.15rem;
  padding: 2rem 0rem;
  overflow-y: scroll;
  max-height: 24rem;
  cursor: pointer;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__details .label-value-box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__details .label-value-box .label {
  color: rgb(139, 139, 139);
  font-size: 1.2rem;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__details .label-value-box .value {
  font-size: 1.35rem;
  font-weight: 500;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__details .label-value-box .value .tool-tip-wrap {
  font-weight: unset;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.2rem 1.5rem;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  min-width: 25rem;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box .label {
  color: rgb(139, 139, 139);
  font-size: 1.45rem;
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box .label .img-box {
  width: 2rem;
  height: 2rem;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box .label .img-box .img,
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box .label .img-box svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box .value {
  font-size: 2.3rem;
  font-weight: 500;
}
.business-card .business-card__top .business-card__details-balance-box .business-card__balances .label-value-box .value .tool-tip-wrap {
  font-weight: unset;
}
.business-card .business-card__top .verified-badge {
  background-color: #f0fff4;
  color: #2ecc71;
  padding: 5px 10rem;
  margin-top: 1rem;
  border-radius: 1.5rem;
  font-size: 1.2rem;
}
.business-card .wallet-balance,
.business-card .lien-balance {
  border: 1px solid rgb(238, 238, 238);
  padding: 1rem;
  border-radius: 8px;
}
.business-card .wallet_con {
  align-items: center;
  gap: 1rem;
  display: flex;
}
.business-card .lien-balance {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  width: 25%;
}
.business-card .wallet-balance h3,
.business-card .lien-balance h3 {
  margin: 0.5rem 0 0;
  font-size: 1.8rem;
  font-weight: 500;
  color: #333;
}
.business-card .business-card__actions {
  display: flex;
  margin-top: 1rem;
  gap: 2rem;
}
.business-card .business-card__actions .action-btn {
  padding: 1.2rem 1.4rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-size: 1.3rem;
  transition: all 0.2s ease;
  border-width: 0.15rem;
}
.business-card .business-card__actions .action-btn .icon {
  width: 2rem;
  height: 2rem;
}
.business-card .business-card__actions .action-btn .icon svg,
.business-card .business-card__actions .action-btn .icon .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.layout_main {
  box-shadow: none !important;
  padding: 0rem !important;
}
.-layout .children-wrap-box {
  min-height: 55rem !important;
}

/* Top Section */
.single-merchant-unique-bank-name-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.single-merchant-unique-bank-name-box .name {
  font-weight: 600;
}
.single-merchant-unique-bank-name-box .name-number-box .small-text {
  text-wrap: nowrap;
}
.single-merchant-unique-bank-name-box .grey-white-color {
  font-size: 1.2rem;
}
.single-merchant-unique-bank-name-box .icon-wrap {
  border-width: 0.15rem;
}
.single-merchant-unique-bank-name-box .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.single-merchant-unique-bank-name-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.flex_bank_code {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.flex_bank_code .icon-wrap {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border-width: 0.15rem;
}/*# sourceMappingURL=Index.css.map */