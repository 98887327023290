.liveness-modal-container.modal-parent-wrap .raven-modal-content-wrap {
	width: 85rem !important;
	height: 70% !important;
}
.liveliness-check-modal {
	display: flex !important;
	justify-content: center;
	align-items: center;
	// padding: 2rem;

	.modal-content {
		display: flex;
		// align-items: center;
		justify-content: space-between;
		// padding: 2rem;
		background-color: #fff;
		width: 100%;

		.image-container {
			margin-right: 2rem;
		}

		.details-container h2 {
			font-size: 2rem;
			margin-bottom: 2rem;
			font-weight: 600;
		}

		.details-container table {
			width: 100%;
			margin-top: 1rem;
			// border: 1px solid red;
		}

		.details-container td {
			padding: 1.5rem;
			border: 1px solid rgba(227, 227, 227, 1);
		}
		.details-container p {
			color: rgba(0, 0, 0, 1);
			font-size: 1.4rem;
		}
	}
}
