.link-card_container_wrap {
	background-color: #fff;
	margin-bottom: 2.4rem;



		.the_link_item_box {
      display: flex;
      gap: 2rem;

      .qr_code_holder{
			display: flex;
			flex-direction: column;
			flex: 0 0 25%;
			// align-self: stretch;
			position: relative;

			.img-box{
				width: 100%;
				height: 100%;
				border-radius: 1.2rem;
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
      }
		}
  }
		// .verified-badge {
		// 	background-color: #f0fff4;
		// 	color: #2ecc71;
		// 	padding: 5px 10rem;
		// 	margin-top: 1rem;
		// 	border-radius: 1.5rem;
		// 	font-size: 1.2rem;
		// }

		.link_card_details {
			display: flex;
			flex-direction: column;
			gap: 2rem;
      flex: 1;


				.general_link_title {
					color: #000000;
					font-size: 2rem;
					font-weight: 500;
				}
				.general_link_subtitle {
					font-weight: 400;
					font-size: 1.4;
					color: #676767;
				}
			}
			.general_bottom_content_wrap_holder {
				// border-top: 0.2rem solid #f7f7f7;
				// border-bottom: 0.2rem solid #f7f7f7;
				// padding: 2rem;
				// padding-bottom: 2rem;

				// display: grid;
				// grid-template-columns: repeat(4, 1fr);
				// row-gap: 2rem;
				// column-gap: 10rem;

				// .content_wrap_d {
				// 	display: flex;
				// 	flex-direction: column;
				// 	gap: 1rem;
				// 	.content_header_name {
				// 		font-weight: 400;
				// 		font-size: 1.4rem;
				// 		color: #8b8b8b;
				// 	}
				// 	.content_body {
				// 		font-weight: 400;
				// 		font-size: 1.2rem;
				// 		color: #1c2127;
				// 	}
				width: 100%;
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
				align-items: flex-start;
				gap: 3rem;
				row-gap: 2rem;

				border-width: 0.15rem;
				padding: 2rem 0rem;

				.label_value_box {
					display: flex;
					flex-direction: column;
					gap: 0.4rem;

					.name_label{
						color: rgba(139, 139, 139, 1);
						font-size: 1.2rem;
					}

					.value{
						font-size: 1.35rem;
						font-weight: 500;

						.tool-tip-wrap {
							font-weight: unset;
						}
					}
				}
			}
			.link_container_and_disable_box {
				display: flex;
				justify-content: space-between;
				 align-items: center;
				gap: 2rem;
        padding-bottom: 2rem;

				.link_and_message_bod {
					display: flex;
					gap: 1rem;
					align-items: center;
					background-color: #f7f7f7;
					padding: 1rem;
					border-radius: 1rem;
				}
				.disable_and_icon_box {
					display: flex;
				
					gap: 1rem;
					align-items: center;
					border: 0.1rem solid #ffa554;
					border: .1rem solid #FFA554;
					// border-image: linear-gradient(to right, #FFA554, #E56F04) 1;
					cursor: pointer;
					padding: 0.7rem;
					border-radius: 1.5rem;
					border-radius: 1rem; 
				}
			}
		}