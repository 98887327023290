.bankbox-modals {
  display: flex;
  width: 100%;
  overflow-y: scroll;
}
.bankbox-modals__left {
  flex: 0 0 40%;
  position: relative;
  border-radius: var(--Count, 0px);
  padding: 2.8rem;
  padding-top: 15rem;
  position: relative;
}
.bankbox-modals__left--bg-absolute::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  bottom: 0;
  right: 0;
  z-index: -1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../../../../assets/bb-create-user-bg.svg");
  background-size: 100%; /* Adjust the size for the background image */
  background-position: bottom right;
}
.bankbox-modals__left--bg-absolute::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  bottom: 0;
  right: 0;
  z-index: -2;
  cursor: pointer;
  background: linear-gradient(180deg, #f7f7f7 0%, #f7f7f7 100%);
  background-position: bottom right;
}
.bankbox-modals__left--stepper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.bankbox-modals__left--stepper .stepper-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
}
.bankbox-modals__left--stepper .stepper-item.completed span {
  border-color: #014345;
  background-color: #014345;
}
.bankbox-modals__left--stepper .stepper-item.completed span p {
  color: var(--base-white, var(--themelightmode, #fff));
}
.bankbox-modals__left--stepper .stepper-item.active span {
  border-color: #014345;
  background-color: #014345;
}
.bankbox-modals__left--stepper .stepper-item.active span p {
  color: var(--base-white, var(--themelightmode, #fff));
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
}
.bankbox-modals__left--stepper .stepper-item span {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border-radius: 72px;
  border: 1px solid #d1d1d1;
}
.bankbox-modals__left--stepper .stepper-item span p {
  color: var(--raven-black-600, #646464);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
}
.bankbox-modals__left--stepper .stepper-item h6 {
  color: var(--raven-black-600, #646464);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.bankbox-modals__right {
  flex: 1;
  padding: 5.6rem 4.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.bankbox-modals__right .height-box {
  height: 6rem !important;
  width: 100%;
  background-color: red;
}
.bankbox-modals__right--content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.bankbox-modals__right--content .basic-content__title {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.bankbox-modals__right--content .basic-content__title h5 {
  color: var(--themedarkmode, #000);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
}
.bankbox-modals__right--content .basic-content__title h6 {
  color: var(--RAVEN-GREY, #676767);
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
}
.bankbox-modals__right--content .basic-content__business-logo {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
}
.bankbox-modals__right--content .basic-content__business-logo figure {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 72px;
  cursor: pointer;
}
.bankbox-modals__right--content .basic-content__business-logo figure img {
  width: 100%;
  height: 100%;
  border-radius: 72px;
  -o-object-fit: cover;
     object-fit: cover;
}
.bankbox-modals__right--content .basic-content__business-logo p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  width: 40%;
}
.bankbox-modals__right--content .basic-content__input-contain {
  display: flex;
  flex-direction: column;
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--Count, 0px);
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .selector-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .selector-title p {
  color: var(--themedarkmode, #000);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 4.8rem;
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector .bColors {
  width: 5rem;
  height: 5rem;
  display: grid;
  place-items: center;
  border-radius: 72px;
  cursor: pointer;
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector .bColors.active {
  border: 2px solid;
}
.bankbox-modals__right--content .price-content__selected-more-details-box {
  width: 100%;
  margin-top: -3rem;
  margin-bottom: 2.5rem;
  display: flex;
  border-width: 0.15rem;
  border-radius: 1.2rem;
}
.bankbox-modals__right--content .price-content__selected-more-details-box > * {
  gap: 2rem;
  display: flex;
  flex-direction: column;
}
.bankbox-modals__right--content .price-content__selected-more-details-box .label-boxes {
  flex: 0 0 30%;
  border-width: 0.15rem;
}
.bankbox-modals__right--content .price-content__selected-more-details-box .label-boxes .label {
  border-width: 0.15rem;
  padding: 2rem;
}
.bankbox-modals__right--content .price-content__selected-more-details-box .value-boxes {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.bankbox-modals__right--content .price-content__selected-more-details-box .value-boxes .value {
  border-width: 0.15rem;
  font-weight: 600;
  padding: 2rem;
}
.bankbox-modals__right--content .price-content__input-contain {
  margin-top: 2rem;
  margin-bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.bankbox-modals__right--content .price-content__input-contain--grouped {
  display: flex;
  gap: 1.6rem;
}
.bankbox-modals__right--content .left-right-box-wrap {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  margin-top: 2rem;
  gap: 1rem;
  padding: 0rem 1rem;
  border-width: 0.15rem;
  border-radius: 1.2rem;
}
.bankbox-modals__right--content .left-right-box-wrap > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.bankbox-modals__right--content .left-right-box-wrap .left-box {
  flex: 0 0 40%;
  border-width: 0.2rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.bankbox-modals__right--content .left-right-box-wrap .left-box .module-list-box {
  border-radius: 1.2rem;
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.bankbox-modals__right--content .left-right-box-wrap .left-box .module-list-box .name {
  font-size: 1.45rem;
  text-transform: capitalize;
}
.bankbox-modals__right--content .left-right-box-wrap .left-box .module-list-box .item-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bankbox-modals__right--content .left-right-box-wrap .left-box .module-list-box .item-row .toggle-name-box {
  transform: scale(0.8);
}
.bankbox-modals__right--content .left-right-box-wrap .right-box {
  margin-top: 1.5rem;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-left: 2rem;
}
.bankbox-modals__right--content .left-right-box-wrap .right-box .title-permissions-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.bankbox-modals__right--content .left-right-box-wrap .right-box .title-permissions-box .module-title {
  font-weight: 500;
  font-size: 1.8rem;
  text-transform: capitalize;
}
.bankbox-modals__right--content .left-right-box-wrap .right-box .title-permissions-box .permission-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.bankbox-modals__right--content .left-right-box-wrap .right-box .title-permissions-box .permission-box .permission-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.8rem;
  padding: 1.5rem;
  border-width: 0.15rem;
}
.bankbox-modals__right--content .left-right-box-wrap .right-box .title-permissions-box .permission-box .permission-row .permission-row-label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bankbox-create-setup-modal .raven-modal-content-wrap {
  max-height: unset;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals {
  display: flex;
  width: 100%;
  min-height: 75rem;
  max-height: 95vh !important;
  overflow-y: scroll;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left {
  flex: 0 0 40%;
  position: relative;
  border-radius: var(--Count, 0px);
  padding: 2.8rem;
  padding-top: 15rem;
  position: relative;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--bg-absolute::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  bottom: 0;
  right: 0;
  z-index: -1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../../../../assets/bb-create-user-bg.svg");
  background-size: 100%; /* Adjust the size for the background image */
  background-position: bottom right;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--bg-absolute::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  bottom: 0;
  right: 0;
  z-index: -2;
  cursor: pointer;
  background: linear-gradient(180deg, #f7f7f7 0%, #f7f7f7 100%);
  background-position: bottom right;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item.completed span {
  border-color: #014345;
  background-color: #014345;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item.completed span p {
  color: var(--base-white, var(--themelightmode, #fff));
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item.active span {
  border-color: #014345;
  background-color: #014345;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item.active span p {
  color: var(--base-white, var(--themelightmode, #fff));
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item span {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border-radius: 72px;
  border: 1px solid #d1d1d1;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item span p {
  color: var(--raven-black-600, #646464);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__left--stepper .stepper-item h6 {
  color: var(--raven-black-600, #646464);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right {
  flex: 1;
  padding: 5.6rem 4.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__title {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__title h5 {
  color: var(--themedarkmode, #000);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__title h6 {
  color: var(--RAVEN-GREY, #676767);
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__business-logo {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__business-logo figure {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 72px;
  cursor: pointer;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__business-logo figure img {
  width: 100%;
  height: 100%;
  border-radius: 72px;
  -o-object-fit: cover;
     object-fit: cover;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__business-logo p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  width: 40%;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain {
  display: flex;
  flex-direction: column;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain--brand-color {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--Count, 0px);
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain--brand-color .selector-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain--brand-color .selector-title p {
  color: var(--themedarkmode, #000);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 4.8rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector .bColors {
  width: 5rem;
  height: 5rem;
  display: grid;
  place-items: center;
  border-radius: 72px;
  cursor: pointer;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector .bColors.active {
  border: 2px solid;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__form-submit {
  padding-bottom: 4rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__selected-more-details-box {
  width: 100%;
  margin-top: -3rem;
  margin-bottom: 2.5rem;
  display: flex;
  border-width: 0.15rem;
  border-radius: 1.2rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__selected-more-details-box > * {
  gap: 2rem;
  display: flex;
  flex-direction: column;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__selected-more-details-box .label-boxes {
  flex: 0 0 30%;
  border-width: 0.15rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__selected-more-details-box .label-boxes .label {
  border-width: 0.15rem;
  padding: 2rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__selected-more-details-box .value-boxes {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__selected-more-details-box .value-boxes .value {
  border-width: 0.15rem;
  font-weight: 600;
  padding: 2rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__input-contain {
  margin-top: 2rem;
  margin-bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.bankbox-create-setup-modal .raven-modal-content-wrap .content .bankbox-modals__right--content .price-content__input-contain--grouped {
  display: flex;
  gap: 1.6rem;
}

.bankbox-modals__left-two {
  flex: 0 0 25% !important;
}/*# sourceMappingURL=index.css.map */