.small-mobile-flag-out-wrap-index-box {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 48em) {
  .small-mobile-flag-out-wrap-index-box {
    padding: 3rem;
  }
}
.small-mobile-flag-out-wrap-index-box .main-wrapper {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .small-mobile-flag-out-wrap-index-box .main-wrapper {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
  }
}
.small-mobile-flag-out-wrap-index-box .main-wrapper .logo-box .img-box {
  width: 15rem;
  height: 4rem;
}
.small-mobile-flag-out-wrap-index-box .main-wrapper .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.small-mobile-flag-out-wrap-index-box .main-wrapper .mobile-box {
  width: 35rem;
  height: 35rem;
}
@media only screen and (max-width: 48em) {
  .small-mobile-flag-out-wrap-index-box .main-wrapper .mobile-box {
    width: 100%;
    height: 30rem;
  }
}
.small-mobile-flag-out-wrap-index-box .main-wrapper .mobile-box .img-box {
  width: 100%;
  height: 100%;
}
.small-mobile-flag-out-wrap-index-box .main-wrapper .mobile-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.small-mobile-flag-out-wrap-index-box .main-wrapper .big-text-box .text {
  font-size: 3rem;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
@media only screen and (max-width: 48em) {
  .small-mobile-flag-out-wrap-index-box .main-wrapper .big-text-box .text {
    font-size: 2rem;
  }
}/*# sourceMappingURL=MobilePage.css.map */