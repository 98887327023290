.badge-reusable-wrap-index .badge-wrap-index {
  padding: 0.65rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center !important;
  background-color: #fff6ed;
  cursor: pointer;
  border-radius: 3.5rem;
  width: -moz-max-content;
  width: max-content;
  gap: 0.5rem;
}
.badge-reusable-wrap-index .badge-wrap-index span {
  font-weight: 500;
  font-size: 1.3rem;
  color: #ea872d;
  text-transform: capitalize;
  text-align: center !important;
  width: unset !important;
}
.badge-reusable-wrap-index .badge-wrap-index .no-capitalize {
  text-transform: lowercase;
}
.badge-reusable-wrap-index .pending {
  background-color: #fff6ed;
}
.badge-reusable-wrap-index .pending span {
  color: #ea872d;
}
.badge-reusable-wrap-index .info {
  background-color: #e9f5ff;
}
.badge-reusable-wrap-index .info span {
  color: #476885;
}
.badge-reusable-wrap-index .purple {
  background-color: rgb(240, 238, 255);
}
.badge-reusable-wrap-index .purple span {
  color: rgb(117, 90, 226);
}
.badge-reusable-wrap-index .failed {
  background-color: #fff5f5;
}
.badge-reusable-wrap-index .failed span {
  color: #ff0f00;
}
.badge-reusable-wrap-index .success {
  background-color: #e8fff6;
}
.badge-reusable-wrap-index .success span {
  color: #1ACE37;
}/*# sourceMappingURL=BadgeComponent.css.map */