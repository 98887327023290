.title-text-index-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  // display: none;
  width: 100%;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 48em) {
    // flex-direction: column;
    gap: 1rem;
  }

  .back-text-box {
    margin-right: auto;
    display: flex;
    align-items: center;
    flex: 1;

    @media only screen and (max-width: 52em) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      // flex: 0 0 50%;
    }

    .back-box {
      margin-right: 1rem;
      display: grid;
      place-items: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: #014345;
      cursor: pointer;
      transition: transform 0.3s;

      @media only screen and (max-width: 42em) {
        flex: 0 0 9%;
        width: unset;
        height: unset;
      }

      &:hover {
        transform: translateX(-0.1rem);
      }

      .img-box {
        width: 1.2rem;
        height: 1.2rem;
        display: grid;
        place-items: center;
        position: relative;

        @media only screen and (max-width: 42em) {
          width: 3.5rem;
          height: 3.5rem;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @media only screen and (max-width: 42em) {
            width: 40%;
            height: 40%;
          }
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      @media only screen and (max-width: 42em) {
        gap: 1rem;
      }

      .title {
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: -0.05rem;

        @media only screen and (max-width: 42em) {
          font-size: 2rem;
          line-height: 1.5rem;
        }
      }

      .text {
        font-size: 1.4rem;

        @media only screen and (max-width: 42em) {
          font-size: 1.3rem;
          // width: 90%;
        }
      }
    }
  }

  .title-text-box {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 0.7rem;
      line-height: 2.5rem;
 
      @media only screen and (max-width: 48em) {
        font-size: 2.2rem;
      }
    }

    .text {
      font-size: 1.35rem;
      text-align: center;

      @media only screen and (max-width: 48em) {
        text-align: left;
      }
    }
  }

  .children-box {
    @media only screen and (max-width: 48em) {
      //   width: 100%;
      margin-left: auto;
    }
    .btn-insight {
      //   background-color: rgba(250, 250, 255, 1);
      border-color: var(--primary-purple);
      border-width: 0.1rem;

      @media only screen and (max-width: 48em) {
        padding: .8rem 1rem !important;
      }

      .img-box {
        width: 1.8rem;
        height: 1.8rem;

        @media only screen and (max-width: 48em) {
          width: 2.5rem;
          height: 2.5rem;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        color: var(--primary-purple);

        @media only screen and (max-width: 48em) {
          display: none;
        }
      }
    }
  }
}
