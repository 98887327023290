:root {
  --camera-control-offset: 14rem;
}

.webcam-wrapper {
  position: absolute;
  inset: 0;
  background-color: transparent;
  --sheet-height: var(--camera-control-offset);
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(0%);
}

.webcam-wrapper #video-player {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.webcam-wrapper #video-player::after {
  display: none;
  position: absolute;
  content: "";
  width: 90%;
  height: 50%;
  border: 0.15rem solid #1ACE37;
  border-radius: 0.5rem;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(-40%);
}

.webcam-wrapper .camera-controls {
  position: absolute;
  z-index: 10;
  border: none;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  height: var(--camera-control-offset);
  display: none;
}

.webcam-wrapper .camera-controls button {
  border: none;
  background-color: transparent;
  height: 6rem;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.webcam-wrapper .camera-controls:hover {
  background-color: transparent;
}

.webcam-wrapper .preview-controls {
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 1rem;
}

.webcam-wrapper .preview-controls button {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  color: #fff;
  white-space: nowrap;
  width: auto;
}

.webcam-wrapper .preview-controls button:last-of-type {
  justify-content: end;
}

.webcam-wrapper .capture-id-mask {
  position: absolute;
  inset: 0;
  max-height: calc(100% - var(--camera-control-offset));
  display: grid;
  place-items: center;
}
.webcam-wrapper .capture-id-mask::before {
  position: absolute;
  inset: 0;
  content: "";
}

.capture-id-mask img {
  height: 40rem;
  mix-blend-mode: difference;
}

.sdk-snapped-image {
  position: fixed;
  inset: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
}
.sdk-snapped-image .img-div {
  width: 100%;
  height: 100%;
  position: relative;
}
.sdk-snapped-image .img-div .img-box {
  width: 100%;
  height: 100%;
}
.sdk-snapped-image .img-div .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.sdk-snapped-image .camera-controls {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 0 3rem;
  display: none;
}
.sdk-snapped-image .camera-controls svg {
  width: 2.4rem;
}
.sdk-snapped-image .camera-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}
.sdk-snapped-image .camera-controls button span {
  white-space: nowrap;
}
.sdk-snapped-image .camera-controls .refresh-btn {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
}
.sdk-snapped-image .camera-controls .accept-btn {
  width: 100%;
  color: #1ace37;
  margin-left: auto;
  width: -moz-fit-content;
  width: fit-content;
}

.camera-flash-scan {
  position: absolute;
  inset: 0;
  --scan-line-height: 10rem;
  --scan-offset-height: calc(
  	var(--camera-control-offset) + var(--scan-line-height) - 1rem
  );
}
.camera-flash-scan::before {
  position: absolute;
  width: 100%;
  height: var(--scan-line-height);
  animation: ScanLights 0.5s linear 0.5s infinite;
  content: "";
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}
.camera-flash-scan::after {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
}

@keyframes ScanLights {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(35rem);
  }
}
.sdk-scanned-result {
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 11;
}
.sdk-scanned-result > * {
  display: block;
  margin-bottom: 0.4rem;
}
.sdk-scanned-result button {
  margin-top: 2rem;
}

.webcam-wrapper #video-player canvas {
  max-height: 100%;
}

.webcam-wrapper #video-player video {
  position: absolute;
  inset: 0;
  max-height: 100%;
  width: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
}/*# sourceMappingURL=index.css.map */