.bank-box-overview {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}
.bank-box-overview .empty_all_bb {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bank-box-overview .empty_all_bb h6 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  color: #014345;
}
.bank-box-overview .empty_all_bb p {
  color: var(--RAVEN-GREY, #676767);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.bank-box-overview__stats-wrapper {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  align-items: center;
}
.bank-box-overview__stats-wrapper--stats {
  border-radius: 12px;
  background: var(--themelightmode, #fff);
  height: 18.3rem;
  flex: 1 0 0;
  padding: 1.2rem 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2.8rem;
}
.bank-box-overview__stats-wrapper--stats .top {
  width: 100%;
  display: flex;
  justify-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.bank-box-overview__stats-wrapper--stats .top .badge-pending-box {
  position: absolute;
  top: 0rem;
  right: 0rem;
}
.bank-box-overview__stats-wrapper--stats .top figure {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
}
.bank-box-overview__stats-wrapper--stats .top figure svg {
  width: 100%;
  height: 100%;
}
.bank-box-overview__stats-wrapper--stats .top .button {
  border-radius: 72px;
  min-width: 12rem;
  box-shadow: none;
  border: none;
  height: -moz-min-content;
  height: min-content;
  padding: 1rem 0;
  background: var(--raven-grey-shade-10, #f7f8f7);
}
.bank-box-overview__stats-wrapper--stats .top .button p {
  color: var(--themedarkmode, #000);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.bank-box-overview__stats-wrapper--stats .bottom p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.bank-box-overview__stats-wrapper--stats .bottom h6 {
  color: var(--themedarkmode, #000);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.bank-box-overview__bottom-contain {
  width: 100%;
  display: flex;
  flex: 1;
  padding-top: 2.4rem;
  gap: 2rem;
  padding-bottom: 2rem;
}
.bank-box-overview__bottom-contain .top-transacting {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex: 0 0 30%;
  border-radius: 1.2rem;
  padding: 1.6rem;
  height: 100%;
}
.bank-box-overview__bottom-contain .top-transacting__title {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.bank-box-overview__bottom-contain .top-transacting__title figure {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
}
.bank-box-overview__bottom-contain .top-transacting__title figure svg {
  width: 100%;
  height: 100%;
}
.bank-box-overview__bottom-contain .top-transacting__title h6 {
  color: var(--themedarkmode, #000);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}
.bank-box-overview__bottom-contain .top-transacting__users {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 40rem;
  flex: 1;
  margin-top: 3rem;
  overflow-y: auto;
}
.bank-box-overview__bottom-contain .top-transacting__users .top-users {
  display: flex;
  height: 7.2rem;
  padding: 1.5rem 1.6rem;
  align-items: flex-start;
  gap: var(--Count, 10px);
  border-radius: 10px;
  border: 1px solid var(--raven-black-100, #eee);
}
.bank-box-overview__bottom-contain .top-transacting__users .top-users span:first-child {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  border-radius: 72px;
}
.bank-box-overview__bottom-contain .top-transacting__users .top-users span:first-child p {
  color: var(--primary-darkgreen-100, #014345);
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
}
.bank-box-overview__bottom-contain .top-transacting__users .top-users .name-box {
  display: flex;
  flex-direction: column;
}
.bank-box-overview__bottom-contain .top-transacting__users .top-users .name-box .name {
  color: var(--raven-black-800, #333);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}
.bank-box-overview__bottom-contain .top-transacting__users .top-users .name-box .email {
  color: var(--raven-black-500, #8b8b8b);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
}
.bank-box-overview__bottom-contain .charts__graph {
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1.2rem;
  padding: 2.8rem 2.4rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__title {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__title p:first-child {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 140%;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__title .title__graph-indicator {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  margin-top: 1.4rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__title .title__graph-indicator span {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__title .title__graph-indicator span:is(:first-child) p:first-child {
  width: 1.8rem;
  height: 1.8rem;
  background: #014345;
  border-radius: 0.4rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__title .title__graph-indicator span:is(:last-child) p:first-child {
  width: 1.8rem;
  height: 1.8rem;
  background: #9be5c9;
  border-radius: 0.4rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__filter {
  display: flex;
  gap: 1rem;
  padding: 1rem 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 200px;
  align-items: center;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__filter span {
  gap: 0.8rem;
  align-items: center;
  display: flex;
}
.bank-box-overview__bottom-contain .charts__graph .graph__top .top__filter span figure {
  display: grid;
  cursor: pointer;
  place-items: center;
}
.bank-box-overview__bottom-contain .charts__graph .graph__no-record {
  height: 89%;
  background: #f7f7f7;
  border-radius: 0.8rem;
  margin-top: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-image: url("../../../../../assets/images/graph_no_data.svg");
}
.bank-box-overview__bottom-contain .charts__graph .graph__no-record .no-record__text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  width: 50%;
  gap: 0.2rem;
}
.bank-box-overview__bottom-contain .charts__graph .graph__no-record .no-record__text h5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 160%;
  text-align: center;
  color: #014345;
}
.bank-box-overview__bottom-contain .charts__graph .graph__no-record .no-record__text p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  text-align: center;
  text-align: center;
  color: var(--base-background-black);
}
.bank-box-overview__bottom-contain .charts__graph .graph__main {
  width: 100%;
  height: 100%;
  margin-top: 1.8rem;
}/*# sourceMappingURL=index.css.map */