.accept-invite-page-index-wrap-box {
	width: 100vw;
	height: 100vh;
	display: grid;
	place-items: center;
	background-color: rgba(249, 250, 251, 1);
	overflow-y: scroll;

	@media only screen and (max-width: 48em) {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		padding-top: 2rem;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.middle-content {
		width: 45rem;
		display: flex;
		flex-direction: column;
		// gap: 2rem;

		@media only screen and (max-width: 48em) {
			width: 95%;
		}

		.img-logo-box {
			width: 13rem;
			height: 5.5rem;
			align-self: center;
			margin-bottom: 2rem;

			.img-box {
				width: 100%;
				height: 100%;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.content-wrap {
			min-height: 55rem;
			background-color: #ffffff;
			border-radius: 1.2rem;
			box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.form-content {
				flex: 1;
				padding: 3rem;
				padding-bottom: unset;
				display: flex;
				flex-direction: column;

				.form-title {
					font-size: 2.5rem;
					font-weight: 600;
					line-height: 3.3rem;
					margin-bottom: 0.75rem;
				}

				.form-text {
					font-size: 1.55rem;
					color: rgba(139, 139, 139, 1);
					line-height: 2rem;
				}

				.badge-two-role-mail-box {
					margin: 2rem 0rem;
					display: flex;
					align-items: center;
					gap: 1.5rem;
					flex-wrap: wrap;
				}

				.form {
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 2rem;
          margin-top: 1.5rem;
					flex: 1;

					.check-drop-box .bottom-box .check-text-box .check-box-active {
						background-color: rgba(1, 67, 69, 1);
					}

					.btn {
						margin-top: auto;
					}
				}
			}
		}

		.powered-by-box {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			align-self: center;
			margin-top: 2rem;

			.icon-box {
				.img-box {
					width: 2rem;
					height: 2rem;

					.img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}

			.text {
				font-size: 1.3rem;
				font-weight: 600;
				color: rgba(1, 67, 69, 1);
			}
		}
	}
}
