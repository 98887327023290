.reusable-description-box-wrapper-index {
	// width: 100%;
	flex: 1;
	// height: 100%;
	display: flex;
	flex-direction: column;
	// background-color: red;

	.top-box {
		height: 30rem;
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 1),
			rgba(233, 245, 255, 1)
		);
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 60%;
			width: 50%;
			transform: translate(-50%, -30%);
			background-image: url('../../../../../../../assets/center-expire-img.png');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	.top-box-wrong {
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 1),
			rgba(255, 239, 238, 1)
		);

		&::after {
			background-image: url('../../../../../../../assets/wrong-centered-img.png');
		}
	}

	.top-box-success {
		background: linear-gradient(
			to bottom,
			rgba(242, 252, 244, 1),
			rgba(230, 249, 234, 1)
		);

		&::after {
			background-image: url('../../../../../../../assets/success-invite-img-centered.png');
		}
	}

	.bottom-box {
		padding: 2rem;
		display: flex;
		flex-direction: column;
		margin-top: 2rem;

		.title {
			font-size: 2.5rem;
			font-weight: 600;
			line-height: 3rem;
			text-align: center;
			margin-bottom: 1rem;
		}

		.text {
			font-size: 1.55rem;
			color: rgba(139, 139, 139, 1);
			line-height: 2rem;
			text-align: center;
		}
	}
}
