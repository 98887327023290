.bankbox-trx-tbl-name {
	display: flex;
	flex-direction: column;

	p {
		color: var(--themedarkmode, #000);
		font-size: 1.2rem;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 16.8px */
	}

	p:last-child {
		color: var(--RAVEN-GREY, #676767);
	}


}
