.bankbox-status {
  display: grid;
  place-items: center;
  background-color: aliceblue;
  padding: 0.8rem 1.2rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.2rem;
}
.bankbox-status p {
  font-weight: 600;
  font-size: 1.2rem;
}
.bankbox-status.deactivated {
  background-color: #fff5f5;
  text-transform: capitalize;
}
.bankbox-status.deactivated p {
  color: #ff0f00;
}
.bankbox-status.activated {
  background-color: #e8fff6;
  text-transform: capitalize;
}
.bankbox-status.activated p {
  color: #1ace37;
}
.bankbox-status.failed {
  background-color: #fff5f5;
  text-transform: capitalize;
}
.bankbox-status.failed p {
  color: #ff0f00;
}

.bankbox-terminal-view {
  display: flex;
  border-radius: 16px;
  flex: 1;
  width: 100%;
  background: linear-gradient(181deg, rgba(0, 0, 0, 0.01) 1.19%, rgba(0, 0, 0, 0.01) 27.63%, rgba(0, 0, 0, 0) 66.17%, rgba(0, 0, 0, 0) 110.43%), linear-gradient(180deg, #f4f5f4 0%, rgba(244, 245, 244, 0) 100%);
}
.bankbox-terminal-view .view-term-trx-btn {
  background: none;
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.6rem;
  font-style: normal;
  width: -moz-max-content;
  width: max-content;
  font-weight: 600;
  text-wrap: nowrap;
  border-radius: 10px;
  border: 1px solid var(--raven-black-200, #e3e3e3);
}
.bankbox-terminal-view__left {
  flex: 0 0 45%;
}
.bankbox-terminal-view__left {
  background-image: url("../../../../../assets/images/half-bankbox.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.bankbox-terminal-view__right {
  display: flex;
  padding: 2.8rem;
  padding-top: 2.7rem;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: var(--themelightmode, #fff);
  border-right: 1px solid var(--raven-black-100, #eee);
  overflow-y: scroll;
}
.bankbox-terminal-view__right--title {
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  padding-bottom: 2.4rem;
}
.bankbox-terminal-view__right--title h6 {
  color: var(--themedarkmode, #000);
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.bankbox-terminal-view__right--title p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.bankbox-terminal-view__right--date-location {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.2rem 0;
  border-top: 1.5px solid var(--raven-black-100, #eee);
  border-bottom: 1.5px solid var(--raven-black-100, #eee);
  gap: 3.2rem;
}
.bankbox-terminal-view__right--date-location .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
}
.bankbox-terminal-view__right--date-location .item figure {
  display: grid;
  width: 4.5rem;
  height: 4.5rem;
  place-items: center;
}
.bankbox-terminal-view__right--date-location .item figure svg {
  width: 100%;
  height: 100%;
}
.bankbox-terminal-view__right--date-location .item p {
  color: var(--themedarkmode, #000);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.bankbox-terminal-view__right--user-details {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 100%;
  padding-right: 2.3rem;
  margin-top: 2.5rem;
}
.bankbox-terminal-view__right--user-details .details-row {
  display: flex;
  width: 100%;
  gap: 2.4rem;
  align-items: center;
}
.bankbox-terminal-view__right--user-details .details-row .details {
  flex: 1 1 0;
  display: flex;
  display: flex;
  padding: 2.4rem 1.2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid var(--raven-black-100, #eee);
}
.bankbox-terminal-view__right--user-details .details-row .details span {
  color: var(--base-black-base-bg-dark, #676767);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
}
.bankbox-terminal-view__right--user-details .details-row .details p {
  color: var(--base-black-base-background-dark, #020202);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.bankbox-terminal-view {
  height: calc(100% - 30rem);
}

.flier-modal-wrap-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flier-modal-wrap-box .flier_download_btn {
  width: 100%;
  margin-top: auto;
  border-width: 0.2rem;
  border-radius: 0.8rem;
}
.flier-modal-wrap-box .flier_download_btn > * {
  width: 100%;
  background-color: #f7f7f7;
  color: #020202;
  font-weight: 600;
}

.flier-modal-btn-box .form-group__deep-green-light .input-submit {
  background-color: #e8fff6;
  color: #1ace37;
  font-weight: 600;
  border-color: #1ace37 !important;
}

.flier-modal-btn-box .raven-modal-content-wrap {
  width: 50rem;
}

.terminal-info-card-list-index-wrapper-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3rem;
  border-width: 0.15rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.terminal-info-card-list-index-wrapper-box .card-box {
  border-radius: 1.2rem;
  border-width: 0.15rem;
  padding: 1.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  cursor: pointer;
}
.terminal-info-card-list-index-wrapper-box .card-box .volume-box {
  padding: 0rem 1.5rem;
  display: flex;
  align-items: center;
}
.terminal-info-card-list-index-wrapper-box .card-box .volume-box .volume {
  margin-top: 0.75rem;
}
.terminal-info-card-list-index-wrapper-box .card-box .volume-box .badge-wrap-index {
  margin-top: unset;
  display: flex;
  align-items: center;
  background-color: #f7f8f7;
  border-color: #d1d1d1;
  gap: 0.7rem;
  padding: 0.8rem 1.6rem;
}
.terminal-info-card-list-index-wrapper-box .card-box .volume-box .badge-wrap-index:hover .img-box {
  transform: translateX(0.5rem);
}
.terminal-info-card-list-index-wrapper-box .card-box .volume-box .badge-wrap-index .img-box {
  width: 1.7rem;
  height: 1.7rem;
  transition: all 0.3s ease-in-out;
}
.terminal-info-card-list-index-wrapper-box .card-box .volume-box .badge-wrap-index .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.terminal-info-card-list-index-wrapper-box .card-box .label-value-box {
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  gap: 2rem;
}
.terminal-info-card-list-index-wrapper-box .card-box .label-value-box .label {
  font-size: 1.3rem;
}
.terminal-info-card-list-index-wrapper-box .card-box .label-value-box .value {
  font-size: 2rem;
  font-weight: 600;
}/*# sourceMappingURL=index.css.map */